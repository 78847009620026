import './Error.scss'

export default function NotFound() {
    return (
        <div className="page-error">
            <div className="not-found-error">
                <span className="big404">404</span>
                <h1>Página não encontrada</h1>
            </div>
        </div>
    )
}
