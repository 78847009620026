import { formatDate, formatMoney } from '@/utils'
import { Card, CardBody } from './Card'
import { Fatura } from '@/types/fatura'

interface FaturaCardProps {
    fatura: Fatura
    onDetailClick: (detalhes: Fatura['Detalhes']) => void
    canSelect?: boolean
    selected?: boolean
    onSelect?: (fatura: Fatura) => void
    onRemoveSelect?: (cdFatura: Fatura['CdFatura']) => void
}

export default function FaturaCard({ fatura, onDetailClick, canSelect, selected, onSelect, onRemoveSelect }: FaturaCardProps) {
    return (
        <Card className="fatura">
            <CardBody className="flex flex-col gap-2">
                <p className="fatura-desc">{fatura.Titulo}</p>
                {!!fatura.DataPagamento && <p className="fatura-data">Pagamento: {formatDate(fatura.DataPagamento)}</p>}
                <p className="fatura-data">Vencimento: {formatDate(fatura.DataVencimento)}</p>
                <p className="fatura-valor">
                    Valor: {formatMoney(fatura.Valor)}
                    {fatura.Forma ? ` - ${fatura.Forma}` : ''}
                </p>
                {(fatura.Detalhes.length > 0 || canSelect || fatura.Comprovante) && <div className="btn-group">
                    { fatura.Detalhes.length > 0 && (
                        <button
                            className="btn btn-sm btn-outline-primary"
                            onClick={() => onDetailClick(fatura.Detalhes)}
                        >
                            Detalhes
                        </button>
                    )}

                    { canSelect &&
                        (selected ? (
                            <button
                                className="btn btn-sm btn-outline-danger"
                                onClick={() => onRemoveSelect && onRemoveSelect(fatura.CdFatura)}
                            >
                                Remover
                            </button>
                        ) : (
                            <button className="btn btn-sm btn-outline-green" onClick={() => onSelect && onSelect(fatura)}>
                                Selecionar
                            </button>
                        ))
                    }

                    { fatura.Comprovante && (
                        <a
                            className="btn btn-sm btn-outline-primary"
                            href={import.meta.env.VITE_PROOF_OF_PAYMENT_BASE_URL + fatura.Comprovante}
                            target="_blank"
                        >
                            Comprovante
                        </a>
                    )}
                </div>}
            </CardBody>
        </Card>
    )
}
