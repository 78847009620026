import api from "./api"

export async function getFaturas(month: string, year: string, clienteHash?: string) {
    const resp = await api.get(`/clientes/faturas?month=${month}&year=${year}`, {
        headers: {
            clienteHash
        }
    })
    return resp.data
}

export const isFaturaAberta = (fatura) => !fatura.Pago && !fatura.CdBoleto && !fatura.CdPix

export const sortByExpBoleto = (a, b) => (new Date(a.VencimentoBoleto).getTime() > new Date(b.VencimentoBoleto).getTime() ? -1 : 1)
export const sortByExpiration = (a, b) => (new Date(a.DataVencimento).getTime() > new Date(b.DataVencimento).getTime() ? 1 : -1)
export const sortByPayment = (a, b) => (new Date(a.DataPagamento).getTime() > new Date(b.DataPagamento).getTime() ? -1 : 1)

export function groupByBoleto(faturas) {
    return faturas.reduce((group, fatura) => {
        const { CdBoleto, VencimentoBoleto, Pago } = fatura
        if (Pago || !VencimentoBoleto) return group
        group[CdBoleto] = group[CdBoleto] ?? []
        group[CdBoleto].push(fatura)
        return group
    }, []).sort(sortByExpBoleto)
}

export function groupByPix(faturas) {
    return faturas.reduce((group, fatura) => {
        const { CdBoleto, CdPix, Pago } = fatura
        if (Pago || !CdPix || CdBoleto) return group
        group[CdPix] = group[CdPix] ?? []
        group[CdPix].push(fatura)
        return group
    }, [])
}
