import AnimalImage from '@/components/AnimalImage'
import Card from '@/components/Card/Card'
import ErrorHandler from '@/components/Error/ErrorHandler'
import Loading from '@/components/Loading'
import { formatMoney } from '@/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useSWR from 'swr'
import './ListarAnimais.scss'

export default function ListarAnimais() {
    const { data, error } = useSWR('/animais')
    const { t } = useTranslation()

    if (error) return <ErrorHandler error={error} />

    if (!data && !error) {
        return <Loading type="primary" />
    }

    return (
        <div className="animal-list">
            {data?.map((animal) => (
                <Card className="animal-card" key={animal.CdAnimal}>
                    <div className="overlay">
                        <div className="overlay-wrapper">
                            <AnimalImage src={animal.Foto} alt={animal.Nome} />
                        </div>
                        <div className="overlay-layer">
                            <Link to={`animais/${animal.CdAnimal}`} className="btn btn-primary">
                                {t('Editar')}
                            </Link>
                        </div>
                    </div>
                    <p className="animal-name">{animal.Nome}</p>
                    <div className="animal-details">
                        {animal.cliente_planos.slice(0, 2).map((clientePlano, i) => (
                            <p key={i}>
                                {clientePlano.plano.Plano}
                                <span> ({formatMoney(clientePlano.Valor)})</span>
                            </p>
                        ))}
                    </div>
                </Card>
            ))}
        </div>
    )
}
