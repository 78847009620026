import useAuth from '@/contexts/useAuth'
import { Navigate, useLocation, useParams } from 'react-router-dom'

export default function RequireAuth({ children }) {
    const { user, loading } = useAuth()
    const location = useLocation()
    const { empresaURL } = useParams()

    if (!user && !loading) {
        return (
            <Navigate to={empresaURL === 'login' ? '/' : `/${empresaURL}/login`} state={{ from: location }} replace />
        )
    }

    return children
}
