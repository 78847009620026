import { Card, CardBody, CardHeader, CardTitle } from '@/components/Card'
import CardFooter from '@/components/Card/CardFooter'
import ErrorHandler from '@/components/Error/ErrorHandler'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
import api from '@/services/api'
import { formatDatetime, formatMoney } from '@/utils'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import './Atendimentos.scss'

export default function Atendimentos() {
	const [atendimento, setAtendimento] = React.useState(false)
	const [loadingComplementar, setLoadingComplementar] = React.useState(false)
	const { data: atendimentos, error } = useSWR('/animais/atendimentos')
	const {
		handleSubmit: handleComplementarSubmit,
		register: registerComplementar,
		reset: resetComplementar,
	} = useForm()
	const { t } = useTranslation()
	if (error) return <ErrorHandler error={error} />

	if (!atendimentos) {
		return <Loading type="primary" />
	}

	const onComplementarSubmit = async (formData) => {
		setLoadingComplementar(true)
		await api.post(`/animais/atendimentos/checkin?recepcao=${atendimento.CdRecepcao}`, formData)
		setLoadingComplementar(false)
	}

	const openComplementar = (atendimento) => {
		resetComplementar()
		setAtendimento(atendimento)
	}

	return (
		<>
			<Modal open={atendimento} contentProps={{ style: { width: '70%', maxWidth: 700 } }}>
				<div className="modal-header">
					<h3>{t('Dados complementares')}</h3>
					<button
                        title="Fechar"
						type="button"
						className="btn close"
						onClick={() => setAtendimento(false)}
						style={{ padding: 5 }}
					>
						<FontAwesomeIcon icon={faTimes} />
					</button>
				</div>
				<form onSubmit={handleComplementarSubmit(onComplementarSubmit)} className="mobile-break">
					{atendimento?.Checkin?.map((complementar, index) => (
						<div className="form-group" key={index.toString()}>
							<label className="form-label" htmlFor={`pergunta${complementar.CdCheck}`}>
								{complementar.Pergunta}
							</label>
							{complementar.Tipo === 1 ? (
								<div className="custom-radio">
									<label>
										<input
											type="radio"
											id={`opt${complementar.CdCheck}`}
											value="Sim"
											disabled={atendimento.Status !== 0}
											{...registerComplementar(`opt${complementar.CdCheck}`, {
												value: complementar.resposta?.Resposta ?? complementar.Padrao,
											})}
										/>{' '}
										{t('Sim')}
									</label>
									<label>
										<input
											type="radio"
											id={`opt${complementar.CdCheck}`}
											value="Não"
											disabled={atendimento.Status !== 0}
											{...registerComplementar(`opt${complementar.CdCheck}`, {
												value: complementar.resposta?.Resposta ?? complementar.Padrao,
											})}
										/>{' '}
										{t('Não')}
									</label>
								</div>
							) : (
								<>
									<input
										type="text"
										className="form-control"
										id={`pergunta${complementar.CdCheck}`}
										disabled={atendimento.Status !== 0}
										{...registerComplementar(`pergunta${complementar.CdCheck}`, {
											value: complementar.resposta?.Resposta ?? complementar.Padrao,
										})}
									/>
								</>
							)}
						</div>
					))}
					{atendimento.Status === 0 && (
						<CardFooter className="justify-end">
							<button className="btn btn-primary" type="submit" disabled={loadingComplementar}>
								{loadingComplementar && <Loading type="white" />}
								{t('Salvar')}
							</button>
						</CardFooter>
					)}
				</form>
			</Modal>
			<Card style={{ marginBottom: '25px' }}>
				<CardHeader>
					<CardTitle>{t('Atendimentos')}</CardTitle>
				</CardHeader>
			</Card>
			<div className="atendimentos">
				{atendimentos?.map((atendimento) => {
					return (
						<Card className="atendimento" key={atendimento.CdRecepcao}>
							<CardBody className="flex flex-col gap-2">
								<p className="atendimento-desc">{atendimento.Descricao}</p>
								<p className="atendimento-animal">{atendimento.Nome}</p>
								<p className="atendimento-data">
									{t(atendimento.Status === 0 ? 'Data Reserva' : 'Entrada')}:{' '}
									{formatDatetime(
										atendimento.Status === 0 ? atendimento.DataReserva : atendimento.DataEntrada,
									)}
								</p>
								<p className="atendimento-data">
									{t('Saída')}
									{atendimento.Status !== 9 && atendimento.Status < 2 ? ` ${t('Prevista')}` : ''}:{' '}
									{atendimento.Status !== 9
										? atendimento.Status < 2
											? atendimento.SaidaPrevista && atendimento.SaidaPrevista !== '0000-00-00'
												? formatDatetime(atendimento.SaidaPrevista)
												: t('Sem Previsão')
											: formatDatetime(
													atendimento.DataSaida && atendimento.DataSaida !== '0000-00-00'
														? atendimento.DataSaida
														: atendimento.DataPrevista,
												)
										: '-'}
								</p>
								<p className="atendimento-valor">
									{t('Total')}: {formatMoney(Number.parseFloat(atendimento.VlTotal))}
								</p>
								{atendimento.TipoServico === 3 &&
									(atendimento.Status === 0 ||
										atendimento.Checkin.filter((checkin) => checkin.resposta).length > 0) && (
										<button
											type="button"
											className="btn btn-sm btn-outline-primary"
											onClick={() => openComplementar(atendimento)}
										>
											{t('Dados complementares')}
										</button>
									)}
							</CardBody>
						</Card>
					)
				})}
			</div>
		</>
	)
}
