import Loading from '@/components/Loading'
import useAuth from '@/contexts/useAuth'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export default function Login() {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm()

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const { login, user, errors: authErrors, setGlobalLoading, loading: userLoading } = useAuth()
    const [loading, setLoading] = useState(false)
    const { empresaURL } = useParams()

    const from = location.state?.from?.pathname || `/${empresaURL}`

    useEffect(() => {
        if (user && !userLoading) {
            navigate(from, { replace: true })
        }
    }, [userLoading, user, from, navigate])

    const onSubmit = async (data) => {
        setGlobalLoading(false)
        setLoading(true)

        try {
            await login(data.email, data.senha, empresaURL)
        } catch (error) {
            console.log(error)
        } finally {
            setGlobalLoading(true)
            setLoading(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-30px">
                <input
                    type="email"
                    autoComplete="username"
                    placeholder="Email"
                    {...register('email', {
                        required: t('Um endereço de email é obrigatório.'),
                    })}
                />
                <p className="error">{errors?.email?.message}</p>
                <p className="error">{authErrors?.email?.message}</p>
            </div>
            <div className="mb-30px">
                <input
                    type="password"
                    autoComplete="current-password"
                    placeholder={t("Senha")}
                    {...register('senha', {
                        required: t('O campo senha é obrigatório.'),
                    })}
                />
                <p className="error">{errors?.senha?.message}</p>
                <p className="error">{authErrors?.senha?.message}</p>
            </div>
            {authErrors?.message && (
                <div className="mb-30px">
                    <p className="error">{t(authErrors?.message)}</p>
                </div>
            )}
            <div className="action-button">
                {/* <Link className="forget-password" to="/esqueceu-senha">Esqueceu a senha?</Link> */}
                <button className="btn btn-primary submit-auth ml-auto" type="submit" disabled={loading}>
                    {loading ? <Loading /> : t('Acessar')}
                </button>
            </div>
        </form>
    )
}
