import i18n from "i18next"
import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'

export default i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(resourcesToBackend((language) => import(`./locales/${language}.json`)))
    .init({
        fallbackLng: "pt-BR",
    })
