import AnimalImage from '@/components/AnimalImage'
import { Card, CardHeader, CardTitle } from '@/components/Card'
import CardToolbar from '@/components/Card/CardToolbar'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
import { formatDate, formatDatetime } from '@/utils'
import { faArrowLeft, faArrowRight, faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import useSWR from 'swr'
import '../ListarAnimais/ListarAnimais.scss'
import './Manejos.scss'

export default function Manejos() {
    const [date, setDate] = useState(new Date().toISOString().split('T')[0])
    const { data: manejos } = useSWR(`/animais/manejos/${date}`)
    const { t } = useTranslation()
    const [showChecklist, setShowChecklist] = useState(false)
    const [currentChecklist, setChecklist] = useState(null)

    const closeChecklist = () => {
        setShowChecklist(false)
        setChecklist(null)
    }

    const openChecklist = (checklist) => {
        setChecklist(checklist)
        setShowChecklist(true)
    }

    let content = <></>

    if (!manejos) {
        content = <Loading type="primary" />
    } else {
        content = (
            <div className="animal-list manejos">
                {manejos.manejos.map((manejo) => (
                    <Card className="animal-card" key={manejo.CdRecepcao}>
                        <div className="overlay">
                            <div className="overlay-wrapper">
                                <AnimalImage
                                    src={manejo.Foto} alt={manejo.Nome}
                                />
                            </div>
                        </div>
                        <p className="animal-name">{manejo.Animal}</p>
                        <p className="animal-breed">{manejo.Raca}</p>
                        <div className="animal-details">
                            <p>{manejo.Descricao}</p>
                            <p>
                                <FontAwesomeIcon data-tooltip-id="global" data-tooltip-content={t('Entrada')} icon={faSignInAlt} /> &nbsp;
                                {formatDatetime(manejo.DataEntrada)}
                            </p>
                            <p>
                                <FontAwesomeIcon
                                    data-tooltip-id="global"
                                    data-tooltip-content={t('Saída') + (manejo.Status !== 3 ? ' '+t('Prevista') : '')}
                                    icon={faSignOutAlt}
                                />{' '}
                                &nbsp;
                                {formatDatetime(manejo.Status === 3 ? manejo.DataSaida : manejo.SaidaPrevista)}
                            </p>
                        </div>
                        {manejo.Checklist.length > 0 && (
                            <button
                                className="btn btn-sm btn-outline-primary"
                                onClick={() => openChecklist(manejo.Checklist)}
                            >
                                {t('Detalhes')}
                            </button>
                        )}
                    </Card>
                ))}
                <Tooltip id="global" place="top" type="dark" effect="solid" />
            </div>
        )
    }

    return (
        <>
            <Modal open={showChecklist && currentChecklist} className="checklist-modal">
                {Array.isArray(currentChecklist) &&
                    currentChecklist.map((checklist) => (
                        <div className="checklist" key={checklist.CdCheck}>
                            <p>{checklist.Pergunta}</p>
                            <pre><p>{checklist.Resposta}</p></pre>
                        </div>
                    ))}
                <button className="btn btn-primary m-auto" onClick={closeChecklist}>
                    {t('Fechar')}
                </button>
            </Modal>
            <Card>
                <CardHeader className="manejo-header">
                    <CardTitle>{t('Boletim')}</CardTitle>
                    <CardToolbar>
                        <button
                            className="btn"
                            data-manejo={!manejos + ''}
                            data-data={!manejos?.anterior.data}
                            disabled={!(manejos && manejos?.anterior.data)}
                            onClick={() => {
                                if (manejos.anterior.data) {
                                    setDate(manejos.anterior.data)
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>

                        <span>{formatDate(date)}</span>

                        <button
                            className="btn"
                            data-manejo={!manejos + ''}
                            data-data={!manejos?.proximo.data}
                            disabled={!(manejos && manejos?.proximo.data)}
                            onClick={() => {
                                if (manejos.proximo.data) {
                                    setDate(manejos.proximo.data)
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </CardToolbar>
                </CardHeader>
            </Card>
            {content}
        </>
    )
}
