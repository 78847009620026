import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function EsqueceuSenha() {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm()
    const onSubmit = (data) => console.log(data)
    const { t } = useTranslation()

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-30px">
                <input
                    type="email"
                    placeholder="Email"
                    {...register('email', {
                        required: t('Um endereço de email é obrigatório.'),
                    })}
                />
                <p className="error">{errors?.email?.message}</p>
            </div>
            <div className="action-button">
                <Link className="forget-password" to="/login">
                    {t('Voltar')}
                </Link>
                <button className="btn btn-primary" type="submit">
                    {t('Enviar')}
                </button>
            </div>
        </form>
    )
}
