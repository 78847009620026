import { CardBody, CardFooter } from '@/components/Card'
import ErrorHandler from '@/components/Error/ErrorHandler'
import Loading from '@/components/Loading'
import api from '@/services/api'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default function DadosBasicosTab({
    nav, animal, mutateAnimal, avaliacoes, avaliacoesError
}) {
    const { handleSubmit, register } = useForm()
    const { t } = useTranslation()

    const [loadingDadosBasicos, setLoadingDadosBasicos] = useState(false)

    const onSubmit = async (formData) => {
        setLoadingDadosBasicos(true)
        await api.post(`/animais/${animal.CdAnimal}`, formData)
        mutateAnimal({ ...animal, ...formData }, false)
        setLoadingDadosBasicos(false)
    }

    if (avaliacoesError) return <ErrorHandler error={avaliacoesError} />

    return (
        <CardBody nav={nav} name="dados-basicos">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="form-group">
                        <label htmlFor="nome" className="form-label">
                            {t('Nome')}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="nome"
                            {...register('Nome', {
                                required: t('O nome é obrigatório.'),
                                value: animal.Nome,
                            })}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="especie" className="form-label">
                            {t('Espécie')}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="especie"
                            {...register('Especie', {
                                required: t('A espécie é obrigatória.'),
                                value: animal.Especie,
                            })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group">
                        <label htmlFor="raca" className="form-label">
                            {t('Raça')}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="raca"
                            {...register('Raca', {
                                required: t('A raça é obrigatória.'),
                                value: animal.Raca,
                            })}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="cor" className="form-label">
                            {t('Cor')}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="cor"
                            {...register('Cor', {
                                required: t('A cor é obrigatória.'),
                                value: animal.Cor,
                            })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group">
                        <label htmlFor="sexo" className="form-label">
                            {t('Sexo')}:
                        </label>
                        <select
                            className="custom-select form-control"
                            id="sexo"
                            {...register('Sexo', {
                                required: t('O sexo é obrigatório.'),
                                value: animal.Sexo,
                            })}
                        >
                            <option value="M">{t('Macho')}</option>
                            <option value="F">{t('Fêmea')}</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="nascimento" className="form-label">
                            {t('Nascimento')}:
                        </label>
                        <input
                            type="date"
                            className="form-control"
                            id="nascimento"
                            {...register('Nascimento', {
                                required: t('A data de nascimento é obrigatória.'),
                                value: animal.Nascimento,
                            })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group">
                        <label htmlFor="microchip" className="form-label">
                            {t('Microchip')}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="microchip"
                            {...register('Microchip', {
                                value: animal.Microchip,
                            })}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="castrado" className="form-label">
                            {t('Castrado')}:
                        </label>
                        <select
                            className="custom-select form-control"
                            id="castrado"
                            {...register('Castrado', {
                                required: t('O campo castrado é obrigatório.'),
                                value: animal.Castrado,
                            })}
                        >
                            <option value="0">{t('Não')}</option>
                            <option value="1">{t('Sim')}</option>
                        </select>
                    </div>
                </div>
                <CardFooter className="justify-end">
                    <button className="btn btn-primary" type="submit" disabled={loadingDadosBasicos}>
                        {loadingDadosBasicos && <Loading type="white" />}
                        {t('Salvar')}
                    </button>
                </CardFooter>
            </form>
            {avaliacoes && avaliacoes.length > 0 &&
                <>
                    <hr className="opacity-2" />
                    <div className='form'>
                        <h3>{t('AVALIAÇÃO DO ANIMAL')}</h3>
                        {avaliacoes.map((avaliacao, index) => (
                            <div className="form-group" key={index}>
                                <label className="form-label">
                                    {avaliacao.Pergunta}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled
                                    value={avaliacao.resposta?.Resposta}
                                />
                            </div>
                        ))}
                    </div>
                </>
            }
        </CardBody>
    )
}
