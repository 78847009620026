import { AxiosError } from 'axios'
import api from './api'

const auth = {
    async login(email: string, senha: string, empresaURL?: string) {
        try {
            const { data } = await api.post('/login', {
                email,
                senha,
                empresaURL,
            })

            if (!data.user) {
                return {
                    user: null,
                    error: {
                        message: 'Usuário não encontrado, verifique o email e a senha.',
                    },
                }
            }

            localStorage.setItem('token', data.token)

            return { user: data.user, error: null }
        } catch (error) {
            const { response } = error as AxiosError
            
            if (!response) {
                throw new Error('Ocorreu um erro de rede.')
            }
            
            const authError = {
                message: response?.status === 404 
                    ? 'Email ou senha incorretos.' 
                    : `Erro ${response?.status}, tente novamente mais tarde.`
            }

            return { user: null, error: authError }
        }
    },

    async getUser(clienteHash?: string) {
        if (clienteHash) {
            try {
                const { data } = await api.get('/clientes', {
                    headers: {
                        clienteHash
                    }
                })
                
                return data
            } catch (error) {
                return null
            }
        }

        if (!localStorage.getItem('token')) {
            return false
        }

        try {
            const { data } = await api.get('/clientes')
            return data
        } catch (error) {
            localStorage.setItem('token', '')
            return null
        }
    },

    async logout() {
        await api.delete('/logout')

        localStorage.setItem('token', '')
    },
}

export default auth
