import { CardBody, CardHeader, CardTitle, CardToolbar } from "@/components/Card"
import FormGroup from '@/components/FormGroup'
import Loading from "@/components/Loading"
import { useTranslation } from 'react-i18next'

export default function Senha({user, loading, onSubmit, register}) {
    const { t } = useTranslation()
    return <>
        <CardHeader>
            <CardTitle>{t('Alterar Senha')}</CardTitle>
            <CardToolbar>
                <button
                    type="submit"
                    form="senha-form"
                    className="btn btn-primary"
                    disabled={loading}
                >
                    {loading && <Loading type="white" />}
                    {t('Salvar Alterações')}
                </button>
            </CardToolbar>
        </CardHeader>
        <CardBody>
            <form id="senha-form" onSubmit={onSubmit}>
                <FormGroup inline type="password" required autoComplete="current-password" name="oldPassword" label="Senha Antiga" register={register} />
                <FormGroup inline type="password" required autoComplete="new-password" name="password" label="Nova Senha" register={register} />
                <FormGroup inline type="password" required autoComplete="new-password" name="repeatedPassword" label="Repetir Nova Senha" register={register} />
                <div className="form-group-full">
                    <div className="form-label"></div>
                    <div className='form-control' style={{ height: 'auto' }}>
                        <input type="checkbox" id="politicaPrivacidade" {...register('politicaPrivacidade')} />
                        <label htmlFor="politicaPrivacidade"> {t('Li e concordo com as')} <a href="https://sistemapet.com/politicaprivacidade.html" target="_blank" rel="noreferrer">{t('Políticas de Privacidade')}</a></label>
                    </div>
                </div>
            </form>
        </CardBody>
    </>
}
