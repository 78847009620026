import * as Sentry from '@sentry/react'
import React, { useEffect } from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, createRoutesFromChildren, matchRoutes, Route, Routes, useLocation, useNavigationType } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-tooltip/dist/react-tooltip.css'
import { SWRConfig } from 'swr'
import './assets/global.scss'
import NotFound from './components/Error/NotFound'
import { AuthProvider } from './contexts/useAuth'
import './i18n'
import Atendimentos from './pages/Atendimentos/Atendimentos'
import AuthLayout from './pages/Auth/AuthLayout'
import EsqueceuSenha from './pages/Auth/EsqueceuSenha'
import Login from './pages/Auth/Login'
import DashboardLayout from './pages/Dashboard/DashboardLayout'
import Faturas from './pages/Faturas/Faturas'
import FichaAnimal from './pages/FichaAnimal/FichaAnimal'
import GuestFaturas from './pages/GuestFaturas/GuestFaturas'
import ListarAnimais from './pages/ListarAnimais/ListarAnimais'
import Manejos from './pages/Manejos/Manejos'
import MeusDados from './pages/MeusDados/MeusDados'
import api from './services/api'

const useSentry = Boolean(import.meta.env.VITE_SENTRY_DSN)

if (useSentry) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
        tracesSampleRate: 1.0,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            })
        ]
    })
}

const container = document.getElementById('root')!
const root = ReactDOMClient.createRoot(container)

const SentryRoutes = useSentry ? Sentry.withSentryReactRouterV6Routing(Routes) : Routes

root.render(
    <React.StrictMode>
        <HelmetProvider>
            <ToastContainer />
            <BrowserRouter>
                <SWRConfig
                    value={{
                        fetcher: (url) => api.get(url).then((res) => res.data),
                    }}
                >
                    <AuthProvider>
                        <SentryRoutes>
                            <Route path="c/:clienteHash">
                                <Route path="faturas" element={<GuestFaturas />} />
                            </Route>
                            <Route path=":empresaURL" element={<DashboardLayout />}>
                                <Route path="" element={<ListarAnimais />} />
                                <Route path="animais" element={<ListarAnimais />} />
                                <Route path="animais/:cdAnimal" element={<FichaAnimal />} />
                                <Route path="editar-perfil" element={<MeusDados />} />
                                <Route path="atendimentos" element={<Atendimentos />} />
                                <Route path="faturas" element={<Faturas />} />
                                <Route path="boletim" element={<Manejos />} />
                            </Route>
                            <Route path=":empresaURL" element={<AuthLayout />}>
                                <Route path="login" element={<Login />} />
                                <Route path="esqueceu-senha" element={<EsqueceuSenha />} />
                            </Route>
                            <Route path="*" element={<NotFound />} />
                        </SentryRoutes>
                    </AuthProvider>
                </SWRConfig>
            </BrowserRouter>
        </HelmetProvider>
    </React.StrictMode>
)
