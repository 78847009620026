import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MouseEventHandler, PropsWithChildren } from 'react'

interface CardCollapseButtonProps extends PropsWithChildren {
    className?: string
    collapsed?: boolean
    toggleCollapsed?: MouseEventHandler<HTMLButtonElement>
}

export default function CardCollapseButton({ toggleCollapsed, collapsed, className, children }: CardCollapseButtonProps) {
    return (
        <button style={children ? {} : {width: 32, height: 32}} className={"btn-collapse-card btn " + (className ?? 'btn-light-primary')} onClick={toggleCollapsed}>
            {children ?? <FontAwesomeIcon icon={faChevronUp} style={{ transform: `rotate(${collapsed ? 180 : 0}deg)` }} />}
        </button>
    )
}
