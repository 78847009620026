export default function Footer() {
    return (
        <footer>
            <div className="copyright">
                <span>{new Date().getFullYear()} ©</span> SistemaPET
            </div>
            <img
                className="footer-logo"
                src="https://sistemapet.com/images/logo-dark.png"
                alt="SistemaPET"
            />
        </footer>
    )
}
