import type { CSSProperties, DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from 'react'
import './Modal.scss'

interface ModalProps extends PropsWithChildren<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> {
    open?: boolean
    contentProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
    className?: string
    style?: CSSProperties
}

export default function Modal({ children, open, className, contentProps, style, ...props }: ModalProps) {
    return (
        <div className="modal-overlay" style={{display: open ? 'flex' : ''}} {...props}>
            <div className={`modal-content${className ? ` ${className}` : ''}`} {...contentProps}>
                {children}
            </div>
        </div>
    )
}
