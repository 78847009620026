import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import './Dropdown.scss'

interface DropdownProps {
    children: [ReactElement, ReactElement]
}

export default function Dropdown({ children }: DropdownProps) {
    const [open, setOpen] = useState(false)

    const closeDropdown = useCallback(() => {
        setOpen(false)
    }, [])

    useEffect(() => {
        if (open) {
            setTimeout(() => document.addEventListener('click', closeDropdown), 0)
        } else {
            document.removeEventListener('click', closeDropdown)
        }
        return () => document.removeEventListener('click', closeDropdown)
    }, [open, closeDropdown])

    const [header, list] = children

    const triggerProps = {
        children: header.props.children
            ? React.cloneElement(header.props.children, { onClick: () => setOpen(!open) })
            : null,
    }

    return (
        <div className={'dd-wrapper' + (open ? ' open' : '')}>
            {React.cloneElement(header, triggerProps)}
            {list}
        </div>
    )
}
