import { CardBody, CardHeader, CardTitle, CardToolbar } from "@/components/Card"
import FormGroup from "@/components/FormGroup"
import Loading from "@/components/Loading"
import { useTranslation } from 'react-i18next'

export default function Localizacao({user, loading, onSubmit, register}) {
    const { t } = useTranslation()
    return <>
        <CardHeader>
            <CardTitle>{t('Localização')}</CardTitle>
            <CardToolbar>
                <button
                    type="submit"
                    form="localizacao-form"
                    className="btn btn-primary"
                    disabled={loading}
                >
                    {loading && <Loading type="white" />}
                    {t('Salvar Alterações')}
                </button>
            </CardToolbar>
        </CardHeader>
        <CardBody>
            <form id="localizacao-form" onSubmit={onSubmit}>
                <FormGroup inline name="CEP" value={user.CEP} register={register} />
                <FormGroup inline name="Logradouro" value={user.Logradouro} register={register} />
                <FormGroup inline name="Numero" value={user.Numero} register={register} />
                <FormGroup inline name="Complemento" value={user.Complemento} register={register} required={false} />
                <FormGroup inline name="Bairro" value={user.Bairro} register={register} />
                <FormGroup inline name="Cidade" value={user.Cidade} register={register} />
                <FormGroup inline name="Estado" label="Estado (Sigla)" maxLength="2" value={user.Estado} register={register} />
                <FormGroup inline name="Pais" label="País" value={user.Pais} register={register} />
                <div className="form-group-full">
                    <div className="form-label"></div>
                    <div className='form-control' style={{ height: 'auto' }}>
                        <input type="checkbox" id="politicaPrivacidade" {...register('politicaPrivacidade')} />
                        <label htmlFor="politicaPrivacidade"> {t('Li e concordo com as')} <a href="https://sistemapet.com/politicaprivacidade.html" target="_blank" rel="noreferrer">{t('Políticas de Privacidade')}</a></label>
                    </div>
                </div>
            </form>
        </CardBody>
    </>
}
