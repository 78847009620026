import Logo from '@/components/Logo'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import './Auth.scss'

export default function AuthLayout() {
    const { t } = useTranslation()
    return (
        <main className="login-container">
            <div className="header">
                <Logo className="logo" />
                <h3>{t('Entrar como cliente')}</h3>
                <p>{t('Insira seus dados para acessar sua conta')}:</p>
            </div>
            <Outlet />
            <p className="copyright">
                2015 - {new Date().getFullYear()} © {t('Criado por')} <a href="https://sistemapet.com">SistemaPET</a>.
            </p>
        </main>
    )
}
