import { Fatura } from '@/types/fatura'
import { formatDatetime, formatMoney } from '@/utils'
import { DetailedHTMLProps, TableHTMLAttributes } from 'react'

interface DetalheFaturaTableProps extends DetailedHTMLProps<TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>
{
    detalhesFatura: Fatura['Detalhes']
}

export default function DetalheFaturaTable({ detalhesFatura, ...props }: DetalheFaturaTableProps) {
    if (!detalhesFatura || detalhesFatura.length < 1) return <></>
    return (
        <div className="table-responsive">
            <table {...props}>
                <thead>
                    <tr>
                        {
                            // Atendimento
                            detalhesFatura[0].Tipo === 1 && (
                                <>
                                    <th>Data do Uso</th>
                                    <th>Serviços ou Produtos</th>
                                    <th>Quantidade</th>
                                    <th>Valor Base</th>
                                </>
                            )
                        }
                        {
                            // Plano
                            detalhesFatura[0].Tipo === 2 && (
                                <>
                                    <th>Frequencia</th>
                                    <th>Serviços ou Produtos</th>
                                    <th>Quantidade</th>
                                </>
                            )
                        }
                        {
                            // Venda
                            detalhesFatura[0].Tipo === 3 && (
                                <>
                                    <th>Serviços ou Produtos</th>
                                    <th>Quantidade</th>
                                </>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {detalhesFatura?.map((detalheFatura, index) => {
                        // Atendimento
                        if (detalheFatura.Tipo === 1)
                            return (
                                <tr key={index}>
                                    <td>{formatDatetime(detalheFatura.Data)}</td>
                                    <td>{detalheFatura.Descricao}</td>
                                    <td>{detalheFatura.Quantidade}</td>
                                    {detalheFatura.ValorBase && <td>{formatMoney(detalheFatura.ValorBase)}</td>}
                                </tr>
                            )

                        // Plano
                        if (detalheFatura.Tipo === 2)
                            return (
                                <tr key={index}>
                                    <td>{detalheFatura.Frequencia}</td>
                                    <td>{detalheFatura.Descricao}</td>
                                    <td>{detalheFatura.Quantidade}</td>
                                </tr>
                            )

                        // Venda
                        if (detalheFatura.Tipo === 3)
                            return (
                                <tr key={index}>
                                    <td>{detalheFatura.Descricao}</td>
                                    <td>{detalheFatura.Quantidade}</td>
                                </tr>
                            )

                        return <></>
                    })}
                </tbody>
            </table>
        </div>
    )
}
