import UserBlank from '@/assets/media/user-blank.png'
import { Card, CardBody } from '@/components/Card'
import useAuth from '@/contexts/useAuth'
import api from '@/services/api'
import ViaCEP from '@/services/viacep'
import { faEnvelope, faKey, faMapMarkerAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import InfoPessoais from './InfoPessoais'
import Localizacao from './Localizacao'
import './MeusDados.scss'
import Senha from './Senha'

export function CardCustomNav({ children, target, state, icon }) {
    const [currentForm, setCurrentForm] = state

    return (
        <button
            type="button"
            onClick={() => setCurrentForm(target)}
            className={'btn' + (currentForm === target ? ' active' : '')}
        >
            <span className="nav-icon">
                <FontAwesomeIcon icon={icon} />
            </span>
            <span>{children}</span>
        </button>
    )
}

export default function MeusDados() {
    const {
        handleSubmit,
        register,
        // formState: { errors },
    } = useForm()

    const {
        handleSubmit: handleSubmitLocation,
        register: registerLocation,
        watch: watchLocation,
        setValue: setValueLocation,
        // formState: { errorsLocation },
    } = useForm()

    const {
        handleSubmit: handleSubmitPassword,
        register: registerPassword,
        // formState: { errorsPassword },
    } = useForm()
    
    const { user, setUser } = useAuth()
    const [currentForm, setCurrentForm] = useState('basic-info-form')
    const watchCEP = watchLocation('CEP')
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()

    const onSubmitPassword = async (formData) => {
        if (formData.repeatedPassword !== formData.password) {
            return toast.error(t('As senhas não coincidem.'))
        }

        if (!formData.password || formData.password.length <= 4) {
            return toast.error(t('A nova senha deve ter mais de 4 caracteres.'))
        }

        if (!formData.politicaPrivacidade) {
            return toast.error(t('Você precisa aceitar as políticas de privacidade para continuar.'))
        }

        setLoading(true)
        try {
            await api.post('/clientes/senha', formData)
            toast.success(t('Sua senha foi alterada.'))
        } catch (error) {
            console.error(error)
            if (error?.response?.data) toast.error(error.response.data)
        } finally {
            setLoading(false)
        }
    }

    const onSubmit = async (formData) => {
        if (!formData.politicaPrivacidade) {
            return toast.error(t('Você precisa aceitar as políticas de privacidade para continuar.'))
        }

        setLoading(true)
        try {
            await api.post('/clientes', formData)
            setUser({ ...user, ...formData })
            toast.success(t('Dados alterado com sucesso.'))
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        ;(async () => {
            if (!watchCEP || watchCEP.replaceAll(/\D/g, '').length < 8) return false

            const CEP = await ViaCEP.cep(watchCEP)

            if (!CEP) return false

            setValueLocation('Logradouro', CEP.logradouro)
            setValueLocation('Bairro', CEP.bairro)
            setValueLocation('Cidade', CEP.localidade)
            setValueLocation('Estado', CEP.uf)
            setValueLocation('Pais', 'Brasil')
        })()
    }, [watchCEP, setValueLocation])

    return (
        <div className="row">
            <Card className="user-profile">
                <CardBody>
                    <div className="user-resume">
                        <div className="user-photo">
                            <img src={`${import.meta.env.VITE_LOGO_BASE_URL}/`+user.Foto ?? UserBlank} alt="?" />
                            <div className="user-status"></div>
                        </div>
                        <div className="user-info">
                            <p className="user-name">{user.Nome}</p>
                            <p className="user-email">
                                <FontAwesomeIcon icon={faEnvelope} className="svg-icon" />
                                <span>{user.Email}</span>
                            </p>
                        </div>
                    </div>
                    <div className="separator separator-dashed"></div>
                    <div className="card-nav">
                        <CardCustomNav target="basic-info-form" state={[currentForm, setCurrentForm]} icon={faUser}>
                            {t('Informações pessoais')}
                        </CardCustomNav>
                        <CardCustomNav
                            target="localizacao-form"
                            state={[currentForm, setCurrentForm]}
                            icon={faMapMarkerAlt}
                        >
                            {t('Localização')}
                        </CardCustomNav>
                        <CardCustomNav
                            target="senha-form"
                            state={[currentForm, setCurrentForm]}
                            icon={faKey}
                        >
                            {t('Senha')}
                        </CardCustomNav>
                    </div>
                </CardBody>
            </Card>
            <Card className="user-profile-edit">
                {currentForm === 'basic-info-form' && (
                    <InfoPessoais user={user} loading={loading} onSubmit={handleSubmit(onSubmit)} register={register}/>
                )}

                {currentForm === 'localizacao-form' && (
                    <Localizacao user={user} loading={loading} onSubmit={handleSubmitLocation(onSubmit)} register={registerLocation}/>
                )}

                {currentForm === 'senha-form' && (
                    <Senha user={user} loading={loading} onSubmit={handleSubmitPassword(onSubmitPassword)} register={registerPassword}/>
                )}
            </Card>
        </div>
    )
}
