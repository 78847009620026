import type { CSSProperties, DetailedHTMLProps, HTMLAttributes } from "react"

interface LoadingProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    type?: 'primary' | 'white'
    spinnerStyle?: CSSProperties
    wrapperClass?: string
}

export default function Loading({ type, spinnerStyle, wrapperClass, ...props }: LoadingProps) {
    return (
        <div className={`loading-wrapper${wrapperClass ? ` ${wrapperClass}` : ''}`} {...props}>
            <div className={`spinner${type ? ` spinner-${type}` : ''}`} style={spinnerStyle}/>
        </div>
    )
}
