import axios from 'axios'

export interface ViaCEPResponse {
    cep:         string;
    logradouro:  string;
    complemento: string;
    unidade:     string;
    bairro:      string;
    localidade:  string;
    uf:          string;
    ibge:        string;
    gia:         string;
    ddd:         string;
    siafi:       string;
}

export default class ViaCEP {
    static async cep(cep: string) {
        const { data } = await axios.get<ViaCEPResponse>(`https://viacep.com.br/ws/${cep.replaceAll(/\D/g, '')}/json/`)

        return data
    }
}
