import React, { DetailedHTMLProps } from 'react'

export default function CardLink({ children, className, ...props }: DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) {
    return (
        <div className={'card-link' + (className ? ` ${className}` : '')} {...props}>
            {children}
        </div>
    )
}
