import { DetailedHTMLProps } from "react"

interface CardBodyProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    nav: string
    name: string
    collapsed?: boolean
}

export default function CardBody({ children, nav, name, className, collapsed, ...props }: CardBodyProps) {
    if (nav !== '' && nav !== name) {
        return <></>
    }

    return (
        <div
            className={'card-body' + (className ? ` ${className}` : '') + (collapsed ? ' card-collapsed' : '')}
            {...props}
        >
            {children}
        </div>
    )
}
