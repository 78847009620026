import { formatDate, formatDatetime, formatMoney } from '@/utils'
import clipboard from 'clipboardy'
import { toast } from 'react-toastify'
import { Card, CardHeader, CardTitle, CardToolbar } from '../Card'
import { Dropdown, DropdownItem, DropdownList, DropdownTrigger } from '../Dropdown'
import { Fatura } from '@/types/fatura'

interface GroupedFaturaHeaderProps {
    onCancel?: () => void
    fatura: Fatura
    openPixDetails?: () => void
}

export default function GroupedFaturaHeader({ fatura, openPixDetails }: GroupedFaturaHeaderProps) {
    // const [loading, setLoading] = React.useState(false)

    // const cancelarBoleto = async (cdBoleto) => {
    //     if (!window.confirm('Deseja realmente cancelar o boleto?')) return

    //     setLoading(true)
    //     try {
    //         const res = await api.delete(`/clientes/boletos/${cdBoleto}`)
    //         if (res.data.situacao === 'Cancelado') {
    //             if (onCancel) await onCancel()
    //         } else {
    //             toast.error(
    //                 res.data.errors
    //                     .map((error) => error.fields.map((fieldError) => fieldError.message).join('\n'))
    //                     .join('\n')
    //             )
    //         }
    //     } catch (error) {
    //         console.error(error)
    //         toast.error('Ocorreu um erro inesperado.')
    //     }
    //     setLoading(false)
    // }

    const isBoleto = !!fatura.CdBoleto

    return (
        <Card overflow style={{ marginBottom: '25px' }}>
            <CardHeader className="sm boleto-group-header">
                <CardTitle>Vencimento {isBoleto ? formatDate(fatura.VencimentoBoleto) : formatDatetime(fatura.VencimentoPix)}</CardTitle>
                <CardToolbar>
                    <span style={{ marginRight: 15 }}>{formatMoney(Number(isBoleto ? fatura.ValorBoleto : fatura.ValorPix))}</span>
                    {
                        isBoleto ? <>
                            <Dropdown>
                                <DropdownTrigger>
                                    <button style={{ marginRight: 10 }} className="btn btn-primary btn-sm">
                                        Boleto
                                    </button>
                                </DropdownTrigger>
                                <DropdownList>
                                    {fatura.Link && (
                                        <DropdownItem>
                                            <a href={fatura.Link} target="_blank" rel="noreferrer">
                                                Download
                                            </a>
                                        </DropdownItem>
                                    )}
                                    <DropdownItem>
                                        <button
                                            className="dd-btn"
                                            onClick={() =>
                                                clipboard
                                                    .write(fatura.LinhaDigitavel)
                                                    .then(() => toast.success('Copiado com sucesso', { autoClose: 2000 }))
                                            }
                                        >
                                            Linha digitável
                                        </button>
                                    </DropdownItem>
                                </DropdownList>
                            </Dropdown>
                            {/* <button
                                className="btn btn-sm btn-danger"
                                onClick={() => cancelarBoleto(fatura.CdBoleto)}
                                disabled={loading}
                            >
                                {loading && <Loading type="white" />}
                                Cancelar
                            </button> */}
                        </> : <>
                            <button style={{ marginRight: 10 }} className="btn btn-primary btn-sm" onClick={openPixDetails}>
                                QrCode
                            </button>
                        </>
                    }
                </CardToolbar>
            </CardHeader>
        </Card>
    )
}
