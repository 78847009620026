import React, { DetailedHTMLProps } from 'react'
import './Card.scss'

interface CardProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    overflow?: boolean
    collapsable?: boolean
}

export default function Card({ children, overflow, className, collapsable, ...props }: CardProps) {
    return (
        <div className={'card' + (collapsable ? ' card-collapsable' : '') + (className ? ` ${className}` : '') + (overflow ? ' overflow' : '')} {...props}>
            {children}
        </div>
    )
}
