import AnimalImage from '@/components/AnimalImage'
import {
    Card, CardHeader,
    CardLink,
    CardTitle,
    CardToolbar
} from '@/components/Card'
import ErrorHandler from '@/components/Error/ErrorHandler'
import Loading from '@/components/Loading'
import useEmpresa from "@/contexts/useEmpresa"
import api from '@/services/api'
import { formatMoney } from '@/utils'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import './FichaAnimal.scss'
import DadosBasicosTab from './Tabs/DadosBasicosTab'
import OrientacoesTab from './Tabs/OrientacoesTab'
import SaudeTab from './Tabs/SaudeTab'
import { FeatureFlagEnum } from '@/types/empresa'

export default function FichaAnimal() {
    const [uploadingImage, setUploadingImage] = useState(false)
    const { t } = useTranslation()

    const { cdAnimal } = useParams()
    const { data: animal, error: animalError, mutate: mutateAnimal } = useSWR(`/animais/${cdAnimal}`)
    const { data: avaliacoes, error: avaliacoesError } = useSWR(`/empresa/avaliacoes?animal=${cdAnimal}`, {revalidateOnFocus: false})
    const { hasFeature } = useEmpresa()

    const [cardBody, setCardBody] = useState('dados-basicos')

    const uploadAnimalImage = async (imageInput, cdAnimal) => {
        if (imageInput.files.length < 1) return false;

        if (imageInput.files[0].size / 1024 / 1024 > 5) {
            return alert(t('Tamanho do arquivo muito grande, máximo 5MB!'))
        }
        
        try {
            setUploadingImage(true)
    
            const formData = new FormData();
            formData.append("image", imageInput.files[0]);
            
            const {data} = await api.post(`animais/${cdAnimal}/images`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            
            await mutateAnimal({ ...animal, Foto: data }, false)
        } catch (error) {
            console.error(error)
            alert(t('Ocorreu um erro inesperado'))
        }
        setUploadingImage(false)
    }

    if (animalError) return <ErrorHandler error={animalError} />

    if (!animal) {
        return <Loading type="primary" />
    }

    return (
        <div className="animal-profile-wrapper">
            <Card className="animal-profile">
                <div className="overlay">
                    <div className="overlay-wrapper">
                        <AnimalImage src={animal.Foto} alt={animal.Nome} className="animal-image" />
                        {uploadingImage && <Loading />}
                    </div>
                    {hasFeature(FeatureFlagEnum.UploadAnimalImage) && <div className={`overlay-layer${uploadingImage ? ' disabled' : ''}`}>
                        <label className='btn btn-primary'>
                            {t('Enviar foto')}
                            <input type="file" name="uploadAnimalImage" id="uploadAnimalImage" style={{display: 'none'}} accept="image/*" onChange={(e) => uploadAnimalImage(e.target, animal.CdAnimal)}/>
                        </label>
                    </div>}
                </div>
                <span className="animal-name">{animal.Nome}</span>
                <div className="animal-plans">
                    {animal.cliente_planos.map((clientePlano, i) => (
                        (
                            clientePlano.plano && <p className="animal-plan" key={i.toString()}>
                                {clientePlano.plano.Plano}
                                <span> {formatMoney(clientePlano.Valor)}</span>
                            </p>
                        )
                    ))}
                </div>
            </Card>
            <Card className="animal-edit-profile mobile-break">
                <CardHeader>
                    <CardTitle>{t('FICHA COMPLETA')}</CardTitle>
                    <CardToolbar state={[cardBody, setCardBody]}>
                        <CardLink to="dados-basicos">{t('Dados Básicos')}</CardLink>
                        <CardLink to="saude">{t('Saúde')}</CardLink>
                        <CardLink to="orientacoes">{t('Orientações')}</CardLink>
                    </CardToolbar>
                </CardHeader>
                <DadosBasicosTab nav={cardBody} {...{animal, mutateAnimal, avaliacoes, avaliacoesError}}/>
                <SaudeTab nav={cardBody} animal={animal}/>
                <OrientacoesTab nav={cardBody} {...{cdAnimal}}/>
            </Card>
        </div>
    )
}
