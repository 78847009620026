import Footer from '@/components/Footer'
import Logo from '@/components/Logo'
import RequireAuth from '@/components/RequireAuth'
import useAuth from '@/contexts/useAuth'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import './DashboardLayout.scss'

export default function DashboardLayout() {
    const { logout, user, loading: userLoading } = useAuth()
    const [headerNav, setHeaderNav] = useState(false)
    const location = useLocation()

    const { t } = useTranslation()

    useEffect(() => {
        setHeaderNav(false)
    }, [location])

    const navLinks = (
        <>
            <NavLink to="editar-perfil">{t('Meus Dados')}</NavLink>
            <NavLink to="" end>{t('Meus Pets')}</NavLink>
            <NavLink to="atendimentos">{t('Atendimentos')}</NavLink>
            <NavLink to="faturas">{t('Faturas')}</NavLink>
            <NavLink to="boletim">{t('Boletim')}</NavLink>
        </>
    )

    const toggleHeaderNav = () => {
        setHeaderNav(!headerNav)
    }

    return (
        <RequireAuth>
            {user && (
                <>
                    <header>
                        <div className="nav-container">
                            <Logo className="logo" />
                            <nav className={headerNav ? 'active' : ''}>{navLinks}</nav>
                        </div>
                        <div className="right">
                            <div className="hello">
                                <span>{t('Olá')}, </span>
                                <strong>{user?.Nome?.split(' ')[0]}</strong>
                            </div>
                            <button
                                className="btn btn-outline-danger logout"
                                disabled={userLoading}
                                onClick={() => logout()}
                            >
                                {t('Sair')}
                            </button>
                            <button className="btn mobile-nav-btn" onClick={() => toggleHeaderNav()}>
                                <FontAwesomeIcon icon={faBars} />
                            </button>
                        </div>
                        <div
                            className={'nav-container-overlay' + (headerNav ? ' active' : '')}
                            onClick={() => toggleHeaderNav()}
                        ></div>
                    </header>
                    <main>
                        <Outlet />
                    </main>
                    <Footer />
                </>
            )}
        </RequireAuth>
    )
}
