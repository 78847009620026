import amex from '@/assets/media/creditcard/amex.svg'
import dinersclub from '@/assets/media/creditcard/dinersclub.svg'
import discover from '@/assets/media/creditcard/discover.svg'
import elo from '@/assets/media/creditcard/elo.svg'
import hipercard from '@/assets/media/creditcard/hipercard.svg'
import jcb from '@/assets/media/creditcard/jcb.svg'
import mastercard from '@/assets/media/creditcard/mastercard.svg'
import visa from '@/assets/media/creditcard/visa.svg'
import useAuth from '@/contexts/useAuth'
import useEmpresa from '@/contexts/useEmpresa'
import api from '@/services/api'
import { formatDate, formatMoney, validateEmail, validatePhone, getTipoPix } from '@/utils'
import { faCheckCircle, faCreditCard, faHourglassHalf, faMoneyBill, faReceipt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cardValidator from 'card-validator'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import FaturaCard from '../FaturaCard'
import Loading from '../Loading'
import Modal from '../Modal'
import DetalheFaturaModal from './DetalheFaturaModal'
import PixModalContent from './PixModalContent'
import * as Sentry from '@sentry/react'
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons/faFileInvoiceDollar'
import type { Empresa } from '@/types/empresa'
import type { Fatura } from '@/types/fatura'
import type { PagamentoBoleto, PagamentoCartao, PagamentoPix } from '@/types/pagamento'

function formaPagamentoPadrao(empresa?: Empresa | null) {
    if (!empresa) {
        return ''
    }

    if (empresa.CdFormaPix > 0) {
        return 'pix'
    }

    if (empresa.CdFormaBoleto > 0) {
        return 'boleto'
    }

    if (empresa.CdFormaCartao > 0) {
        return 'cartao'
    }

    if (empresa.PermiteDeposito) {
        return 'bank_deposit'
    }

    return ''
}

interface PagamentoModalProps {
    faturas: Fatura[]
    open: boolean
    onClose: () => void
    onPaymentComplete: () => Promise<void>
}

export default function PagamentoModal({ faturas, open, onClose, onPaymentComplete }: PagamentoModalProps) {
    const { empresa } = useEmpresa()
    const [formaPagamento, setFormaPagamento] = useState(formaPagamentoPadrao(empresa))
    const [cardNumberType, setCardNumberType] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [completed, setCompleted] = useState<false | PagamentoPix | PagamentoBoleto | PagamentoCartao>(false)
    const { register, handleSubmit, watch } = useForm()
    const { register: registerPix, handleSubmit: handlePixSubmit } = useForm()
    const { user } = useAuth()
    const [detalhesFatura, setDetalheFatura] = useState<Fatura['Detalhes'] | null>(null)
    const watchCardNumber = watch('numero')
    const { clienteHash } = useParams()
    const [loadingProofOfPayment, setLoadingProofOfPayment] = useState(false)

    const creditcardIcons = {
        'american-express': amex,
        'diners-club': dinersclub,
        discover,
        elo,
        hipercard,
        jcb,
        mastercard,
        visa,
    }

    const uploadProofOfPayment = async (file: File | null) => {
        if (!file) {
            return
        }

        setLoadingProofOfPayment(true)

        try {
            await api.postForm('/clientes/faturas/pagamentos/comprovantes', {
                file,
                faturas: faturas.map((fatura) => fatura.CdFatura)
            }, {
                headers: {
                    clienteHash,
                }
            })

            onClose()
            await onPaymentComplete()
            toast.success('Comprovante enviado com sucesso!')
        } catch (error) {
            console.error(error)
            toast.error('Ocorreu um erro inesperado.')
            Sentry.captureException(error)
        } finally {
            setLoadingProofOfPayment(false)
        }
    }
    useEffect(() => {
        const cardType = cardValidator.number(watchCardNumber)?.card?.type

        setCardNumberType(cardType)
    }, [watchCardNumber])

    const onError = (error) => {
        toast.error(error)
        setLoading(false)
        return true
    }

    const verifyPersonalData = () => {
        let hasError = false

        if (!user?.CPF || !user.CPF.replace(/\D/g, '')) {
            hasError = onError('Verifique seu CPF e tente novamente!')
        }

        if (!user?.Nome) {
            hasError = onError('Verifique seu nome.')
        }

        if (!user?.Email || !validateEmail(user.Email)) {
            hasError = onError('Verifique seu email.')
        }

        if (!user?.Telefone || !validatePhone(user.Telefone)) {
            hasError = onError('Verifique seu telefone.')
        }

        if (!user?.CEP) {
            hasError = onError('Verifique seu CEP.')
        }

        if (!user?.Logradouro) {
            hasError = onError('Verifique seu logradouro.')
        }

        if (!user?.Bairro) {
            hasError = onError('Verifique seu bairro.')
        }

        if (!user?.Numero) {
            hasError = onError('Verifique o numero da residência.')
        }

        if (!user?.Cidade) {
            hasError = onError('Verifique sua cidade.')
        }

        if (!user?.Estado) {
            hasError = onError('Verifique seu estado.')
        }

        if (!user?.CPF || !user.CPF.replace(/\D/g, '')) {
            hasError = onError('Verifique seu CPF e tente novamente!')
        }

        return !hasError
    }

    const gerarBoleto = async () => {
        if (!verifyPersonalData()) {
            return false
        }

        setLoading(true)
        try {
            const { data } = await api.post('/clientes/faturas/pagamentos', {
                tipo: 'boleto',
                codFaturas: faturas.map((fatura) => fatura.CdFatura),
            }, {
                headers: {
                    clienteHash
                }
            })

            if (data.errors) {
                return onError(
                    data.errors
                        .map((error) => error.fields.map((fieldError) => fieldError.message).join('\n'))
                        .join('\n')
                )
            }

            await onPaymentComplete()
            setCompleted(data)
            setLoading(false)
        } catch (error) {
            onError('Ocorreu um erro inesperado.')
        }
    }

    const gerarPagamentoCartao = async (cartao) => {
        if (loading) return

        if (!verifyPersonalData()) {
            return false
        }

        setLoading(true)

        if (!cardNumberType) {
            return onError('Número do cartão inválido ou não suportado.')
        }

        try {
            const { data } = await api.post('/clientes/faturas/pagamentos', {
                tipo: 'cartao',
                codFaturas: faturas.map((fatura) => fatura.CdFatura),
                cartao: {
                    bandeira: cardNumberType,
                    validade: `${cartao.year}-${(`0${cartao.month}`).slice(-2)}`,
                    ...cartao,
                },
            }, {
                headers: {
                    clienteHash
                }
            })

            if (data.error) {
                return onError(data.error)
            }

            if (data.errors) {
                return onError(
                    data.errors
                        .map((error) => error.fields.map((fieldError) => fieldError.message).join('\n'))
                        .join('\n')
                )
            }

            await onPaymentComplete()
            setCompleted(data)
            setLoading(false)
        } catch (error) {
            onError('Ocorreu um erro inesperado.')
        }
    }

    const gerarPix = async (dadosPessoais) => {
        if (!dadosPessoais.cpfCnpj || !dadosPessoais.cpfCnpj.replace(/\D/g, '')) {
            return onError('Verifique seu CPF e tente novamente!')
        }

        if (!dadosPessoais.nomeCompleto) {
            return onError('Verifique seu nome.')
        }

        if (!dadosPessoais.telefone || dadosPessoais.telefone.length < 10) {
            return onError('O numero de telefone deve conter pelo menos 10 numeros, DDD + Numero.')
        }

        setLoading(true)

        try {
            const { data } = await api.post('/clientes/faturas/pagamentos', {
                tipo: 'pix',
                codFaturas: faturas.map((fatura) => fatura.CdFatura),
                ...dadosPessoais
            }, {
                headers: {
                    clienteHash
                }
            })

            if (data.message) {
                onError(data.message)
            }

            if (data.action === 'reload') {
                onClose()
                await onPaymentComplete()
                return
            }

            if (data.errors) {
                return onError(
                    data.errors
                        .map((error) => error.fields.map((fieldError) => fieldError.message).join('\n'))
                        .join('\n')
                )
            }

            await onPaymentComplete()
            setCompleted(data)
        } catch (error) {
            console.log(error)
            onError('Ocorreu um erro inesperado.')
        } finally {
            setLoading(false)
        }
    }

    return (<>
        <Modal open={open} className="modal-pagamento" >
            {loading && <Loading type="primary" wrapperClass="modal-overlay" />}
            {completed && (
                <div className={`${completed.Tipo === 'pix' ? '' : "pagamento-finalizado "}bg-white`}>
                    {completed.Tipo === 'boleto' && (
                        <div className="flex-1 flex flex-col gap-2">
                            <h3 className='text-center'>Pagamento com Boleto</h3>
                            <div className='flex-1 flex flex-col justify-center gap-2'>
                                <div>
                                    <strong>Valor</strong>
                                    <p>{formatMoney(completed.Valor)}</p>
                                </div>
                                <div>
                                    <strong>Linha Digitável</strong>
                                    <p className='break-all'>{completed.LinhaDigitavel}</p>
                                </div>
                                <div>
                                    <strong>Vencimento</strong>
                                    <p>{formatDate(completed.Vencimento)}</p>
                                </div>
                            </div>
                            <div className='flex gap-2'>
                                <button type='button' onClick={onClose} className="btn btn-danger">
                                    Fechar
                                </button>
                                <a
                                    className="btn btn-primary flex-1"
                                    href={completed.Link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Baixar
                                </a>
                            </div>
                        </div>
                    )}
                    {completed.Tipo === 'cartao' && (
                        <div style={{ display: 'flex', padding: 15, flex: 1, flexDirection: 'column', minHeight: 300 }}>
                            <h3 style={{ textAlign: 'center' }}>{completed.situacao === 'pendente' ? 'Aguardando pagamento' : 'Pagamento efetuado'}</h3>
                            <div
                                style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <FontAwesomeIcon size='6x' style={completed.situacao === 'pendente' ? { opacity: .6 } : { color: 'green' }} icon={completed.situacao === 'pendente' ? faHourglassHalf : faCheckCircle} />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <button type='button' onClick={onClose} style={{ flex: 1 }} className="btn btn-danger">
                                    Fechar
                                </button>
                            </div>
                        </div>
                    )}
                    {completed.Tipo === 'pix' && (
                        <PixModalContent pixModal={completed} onClose={onClose} />
                    )}
                </div>
            )}

            {!completed && (<>
                <div className="modal-header">
                    <h3>Pagamento</h3>
                    <button type='button' title="Fechar" className="btn close" onClick={onClose} style={{ padding: 5 }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <div className='pagamento-container'>
                    <div className="detalhe-faturas-list">
                        {faturas.map((fatura) => (
                            <FaturaCard
                                fatura={fatura}
                                onDetailClick={setDetalheFatura}
                                key={fatura.CdFatura}
                            />
                        ))}
                    </div>

                    <div className="pagamento">
                        <select
                            title='Métodos de pagamento'
                            name="metodoPagamento"
                            id="metodoPagamento"
                            className="custom-select form-control"
                            value={formaPagamento}
                            onChange={(e) => setFormaPagamento(e.target.value)}
                        >
                            {empresa?.CdFormaPix && empresa?.CdFormaPix > 0 && <option value="pix">Pix</option>}
                            {empresa?.CdFormaBoleto && empresa?.CdFormaBoleto > 0 && <option value="boleto">Boleto</option>}
                            {empresa?.CdFormaCartao && empresa?.CdFormaCartao > 0 && <option value="cartao">Cartão de Crédito</option>}
                            {empresa?.PermiteDeposito && <option value="bank_deposit">Depósito Bancário</option>}
                        </select>

                        {formaPagamento === 'bank_deposit' && (
                            <>
                                <div className="flex gap-2">
                                    <div className="form-group">
                                        <label className='form-label' htmlFor="titular">Titular</label>
                                        <input type="text" className="form-control" id="titular" disabled defaultValue={user?.ContaBancariaEmpresa.TitularConta} />
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label" htmlFor="banco">Banco</label>
                                        <input type="text" className="form-control" id="banco" disabled defaultValue={user?.ContaBancariaEmpresa.Banco} />
                                    </div>
                                </div>
                                <div className="flex gap-2">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="pix">Pix</label>
                                        <input type="text" className="form-control" id="pix" disabled defaultValue={user?.ContaBancariaEmpresa.PIX} />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="tipoPix">Tipo Pix</label>
                                        <input type="text" className="form-control" id="tipoPix" disabled defaultValue={getTipoPix(user?.ContaBancariaEmpresa.TipoPix)} />
                                    </div>
                                </div>
                                <div className='flex'>
                                    <label className='ml-auto text-sm btn btn-outline-primary'>
                                        <FontAwesomeIcon icon={faFileInvoiceDollar} className='mr-2' /> Selecionar comprovante
                                        <input className='hidden' type="file" onChange={(e) => e.target.files && uploadProofOfPayment(e.target.files[0])} disabled={loadingProofOfPayment} />
                                    </label>
                                    {loadingProofOfPayment && <Loading type="primary" wrapperClass="modal-overlay" />}
                                </div>
                            </>
                        )}

                        {formaPagamento === 'boleto' && (
                            <button type="button" onClick={gerarBoleto} className="btn btn-primary full-size pay-btn">
                                <FontAwesomeIcon icon={faReceipt} /> Gerar Boleto
                            </button>
                        )}

                        {formaPagamento === 'cartao' && (
                            <form onSubmit={handleSubmit(gerarPagamentoCartao)}>
                                <div className="row">
                                    <div className="form-group">
                                        <label htmlFor="titular" className="form-label">
                                            Nome no cartão
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="titular"
                                            {...register('titular', { required: true })}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group">
                                        <label htmlFor="numero" className="form-label">
                                            Número do cartão
                                        </label>
                                        <input
                                            style={{ backgroundImage: cardNumberType && creditcardIcons[cardNumberType] ? `url('${creditcardIcons[cardNumberType]}')` : undefined }}
                                            type="number"
                                            className="form-control"
                                            id="numero"
                                            {...register('numero', { required: true, maxLength: 16 })}
                                        />
                                    </div>
                                    <div className="form-group mw-70">
                                        <label htmlFor="cvv" className="form-label">
                                            CVV
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="cvv"
                                            {...register('cvv', { required: true })}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group">
                                        <label htmlFor="month" className="form-label">
                                            Validade
                                        </label>
                                        <select {...register('month')} id="month" className="custom-select form-control">
                                            {[
                                                'Janeiro',
                                                'Fevereiro',
                                                'Março',
                                                'Abril',
                                                'Maio',
                                                'Junho',
                                                'Julho',
                                                'Agosto',
                                                'Setembro',
                                                'Outubro',
                                                'Novembro',
                                                'Dezembro',
                                            ].map((mes, index) => (
                                                <option key={mes} value={index + 1}>
                                                    {mes}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <select {...register('year')} id="year" className="custom-select form-control">
                                            {[...Array(20).keys()].map((num) => (
                                                <option key={num} value={new Date().getFullYear() + num}>
                                                    {new Date().getFullYear() + num}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='recorrente-container'>
                                    <input
                                        type="checkbox"
                                        id="recorrente"
                                        {...register('recorrente', {})}
                                    />
                                    <label htmlFor="recorrente">
                                        Tornar o Faturamento Automático e Recorrente
                                    </label>
                                </div>
                                <button type="submit" className="btn btn-primary full-size pay-btn" disabled={loading}>
                                    <FontAwesomeIcon icon={faCreditCard} /> Pagar agora
                                </button>
                            </form>
                        )}

                        {formaPagamento === 'pix' && (
                            <form onSubmit={handlePixSubmit(gerarPix)}>
                                <div className="row">
                                    <div className="form-group">
                                        <label htmlFor="nomeCompleto" className="form-label">
                                            Nome completo
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nomeCompleto"
                                            {...registerPix('nomeCompleto', { required: true, value: user?.Nome })}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group">
                                        <label htmlFor="cpfCnpj" className="form-label">
                                            CPF
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cpfCnpj"
                                            {...registerPix('cpfCnpj', { required: true, value: user?.CPF })}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group">
                                        <label htmlFor="telefone" className="form-label">
                                            Telefone
                                        </label>
                                        <input
                                            type="tel"
                                            className="form-control"
                                            id="telefone"
                                            {...registerPix('telefone', { required: true, value: user?.Telefone })}
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary full-size pay-btn" disabled={loading}>
                                    <FontAwesomeIcon icon={faMoneyBill} /> Pagar agora
                                </button>
                            </form>
                        )}
                    </div>
                </div>
            </>)}
        </Modal>
        {detalhesFatura && <DetalheFaturaModal detalhesFatura={detalhesFatura} onClose={() => setDetalheFatura(null)} />}
    </>
    )
}
