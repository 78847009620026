import api from '@/services/api'
import { htmlDecode } from '@/utils'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { matchPath, useLocation } from 'react-router-dom'
import useAuth from './useAuth'
import type { Empresa, FeatureFlagEnum } from '@/types/empresa'

interface EmpresaContextType {
	empresa: Empresa | null
	hasFeature: (feature: FeatureFlagEnum) => boolean
}

const EmpresaContext = createContext({} as EmpresaContextType)

export const MODULO_MEU_WHATSAPP = 13

export function EmpresaProvider({ children }) {
	const { user, logout, loading } = useAuth()
	const [empresa, setEmpresa] = useState<EmpresaContextType['empresa']>(null)
	const { pathname } = useLocation()
	const path = matchPath({ path: '/:empresaURL/*' }, pathname)
	const empresaURL = path?.params.empresaURL

	useEffect(() => {
		if (!empresaURL) return
		;(async () => {
			try {
				if (empresaURL === 'c' && !user) return

				const empresaLocal = localStorage.getItem('empresa')
				if (empresaLocal) setEmpresa(JSON.parse(empresaLocal))

				const { data } = await api.get(`/empresa/${empresaURL !== 'c' ? empresaURL : user?.CdEmpresa}`)
				if (data) {
					localStorage.setItem('empresa', JSON.stringify(data))
					setEmpresa(data)
				}
			} catch (error) {
				console.error(error)
			}
		})()
	}, [empresaURL, user])

	useEffect(() => {
		if (empresaURL === 'c' || !empresa || !user || loading) return

		if (empresa.CdEmpresa !== user.CdEmpresa) {
			if (logout) logout()
		}
	}, [empresaURL, empresa, user, logout, loading])

	const hasFeature: EmpresaContextType['hasFeature'] = useCallback(
		(feature) => {
			return empresa?.FeatureFlags.find((flag) => flag.CdFeatureFlag === feature)?.Value ?? false
		},
		[empresa],
	)

	const value: EmpresaContextType = {
		empresa,
		hasFeature,
	}

	return (
		<EmpresaContext.Provider value={value}>
			<Helmet>
				<title>Acesso Cliente - {empresa ? htmlDecode(empresa.Fantasia) : 'SistemaPET'}</title>
			</Helmet>
			{children}
		</EmpresaContext.Provider>
	)
}

export default function useEmpresa() {
	return useContext(EmpresaContext)
}
