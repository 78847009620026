import { Card, CardBody, CardCollapseButton, CardHeader, CardTitle, CardToolbar } from '@/components/Card'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function SaudeTab({
    nav, animal
}) {
    const [vacinaCollapsed, setVacinaCollapsed] = useState(true)
    const [vermifugoCollapsed, setVermifugoCollapsed] = useState(true)
    const [ectoparasitaCollapsed, setEctoparasitaCollapsed] = useState(true)
    const { t } = useTranslation()

    const toggleVacinaCollapsed = () => setVacinaCollapsed(!vacinaCollapsed)
    const toggleVermifugoCollapsed = () => setVermifugoCollapsed(!vermifugoCollapsed)
    const toggleEctoparasitaCollapsed = () => setEctoparasitaCollapsed(!ectoparasitaCollapsed)

    return (
        <CardBody nav={nav} name="saude" className="health-cards">
            <Card className="vaccines-card">
                <CardHeader>
                    <CardTitle>{t('Vacinas')}</CardTitle>
                    <CardToolbar>
                        <CardCollapseButton
                            toggleCollapsed={toggleVacinaCollapsed}
                            collapsed={vacinaCollapsed}
                        />
                    </CardToolbar>
                </CardHeader>
                <CardBody
                    collapsed={vacinaCollapsed}
                    style={
                        vacinaCollapsed
                            ? {}
                            : {
                                border: '1px solid #35AA47',
                                borderTop: 'none',
                            }
                    }
                >
                    <div className="table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>{t('Nome')}</th>
                                    <th>{t('Tipo')}</th>
                                    <th>{t('Veterinário')}</th>
                                    <th>{t('Data')}</th>
                                    <th>{t('Próxima')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {animal.vacinas.map((vacina) => (
                                    <tr key={vacina.CdVacina}>
                                        <td>{vacina.Nome}</td>
                                        <td>{vacina.Tipo}</td>
                                        <td>{vacina.Veterinario}</td>
                                        <td>{new Date(vacina.DataVacina).toLocaleDateString('pt-BR')}</td>
                                        <td>{new Date(vacina.DataVencimento).toLocaleDateString('pt-BR')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
            <Card className="dewormers-card">
                <CardHeader>
                    <CardTitle>{t('Vermífugos')}</CardTitle>
                    <CardToolbar>
                        <CardCollapseButton
                            toggleCollapsed={toggleVermifugoCollapsed}
                            collapsed={vermifugoCollapsed}
                        />
                    </CardToolbar>
                </CardHeader>
                <CardBody
                    collapsed={vermifugoCollapsed}
                    style={
                        vermifugoCollapsed
                            ? {}
                            : {
                                border: '1px solid #67809F',
                                borderTop: 'none',
                            }
                    }
                >
                    <div className="table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>{t('Nome')}</th>
                                    <th>{t('Data')}</th>
                                    <th>{t('Próxima')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {animal.vermifugos.map((vermifugo) => (
                                    <tr key={vermifugo.CdVerme}>
                                        <td>{vermifugo.Descricao}</td>
                                        <td>{new Date(vermifugo.Aplicacao).toLocaleDateString('pt-BR')}</td>
                                        <td>{new Date(vermifugo.Vencimento).toLocaleDateString('pt-BR')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
            <Card className="ectoparasites-card">
                <CardHeader>
                    <CardTitle>{t('Ectoparasitas')}</CardTitle>
                    <CardToolbar>
                        <CardCollapseButton
                            toggleCollapsed={toggleEctoparasitaCollapsed}
                            collapsed={ectoparasitaCollapsed}
                        />
                    </CardToolbar>
                </CardHeader>
                <CardBody
                    collapsed={ectoparasitaCollapsed}
                    style={
                        ectoparasitaCollapsed
                            ? {}
                            : {
                                border: '1px solid #FFB848',
                                borderTop: 'none',
                            }
                    }
                >
                    <div className="table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>{t('Nome')}</th>
                                    <th>{t('Tipo')}</th>
                                    <th>{t('Data')}</th>
                                    <th>{t('Próxima')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {animal.ectoparasitas.map((ectoparasita) => (
                                    <tr key={ectoparasita.CdEcto}>
                                        <td>{ectoparasita.Descricao}</td>
                                        <td>{ectoparasita.Tipo}</td>
                                        <td>{new Date(ectoparasita.Aplicacao).toLocaleDateString('pt-BR')}</td>
                                        <td>{new Date(ectoparasita.Vencimento).toLocaleDateString('pt-BR')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </CardBody>
    )
}
