import Logo from '@/components/Logo'
import auth from '@/services/auth'
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { EmpresaProvider } from './useEmpresa'
import { useCallback } from 'react'
import type { User } from '@/types/user'

interface AuthContextType {
	user: User | null
	errors: Record<string, { message?: string }> | { message?: string }
	login: (email: string, senha: string, empresaURL: string) => Promise<boolean>
	logout: () => Promise<void>
	loading: boolean
	setGlobalLoading: (loading: boolean) => void
	setUser: (user: User | null) => void
}

const AuthContext = createContext({} as AuthContextType)

export function AuthProvider({ children }: PropsWithChildren) {
	const [user, setUser] = useState<AuthContextType['user']>(null)
	const [errors, setErrors] = useState<AuthContextType['errors']>({})
	const [loading, setLoading] = useState(true)
	const [usingGlobalLoading, setGlobalLoading] = useState(true)
	const { pathname } = useLocation()
	const path = matchPath({ path: 'c/:clienteHash/*' }, pathname)
	const clienteHash = path?.params.clienteHash

	const login: AuthContextType['login'] = useCallback(async (email, senha, empresaURL) => {
		setErrors({})
		setLoading(true)

		try {
			const { user, error } = await auth.login(email, senha, empresaURL)

			if (!user && error) {
				setErrors(error)
				return false
			}

			setUser(user)
			return true
		} catch (error) {
			console.error(error)
			setErrors({
				message: 'Ocorreu um erro, tente novamente mais tarde.',
			})
		} finally {
			setLoading(false)
		}
		return false
	}, [])

	const logout: AuthContextType['logout'] = useCallback(async () => {
		setLoading(true)
		try {
			await auth.logout()
		} catch (error) {
			console.error(error)
		}
		setUser(null)
		setLoading(false)
	}, [])

	const checkUser = useCallback(async () => {
		try {
			setLoading(true)

			const currentUser = await auth.getUser(clienteHash)
			setUser(currentUser)
		} finally {
			setLoading(false)
		}
	}, [clienteHash])

	useEffect(() => {
		checkUser()
	}, [checkUser])

	const value: AuthContextType = {
		user,
		errors,
		login,
		logout,
		loading,
		setGlobalLoading,
		setUser,
	}

	return (
		<AuthContext.Provider value={value}>
			<EmpresaProvider>
				{loading && usingGlobalLoading && (
					<div className="page-loader">
						<Logo className="logo" />
						<div className="spinner spinner-primary" />
					</div>
				)}
				{(!loading || !usingGlobalLoading) && children}
			</EmpresaProvider>
		</AuthContext.Provider>
	)
}

export default function useAuth() {
	return useContext(AuthContext)
}
