import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type PropsWithChildren, useState } from "react"
import type { FieldValues, UseFormRegister } from 'react-hook-form'
import { useTranslation } from "react-i18next"

interface FormGroupProps extends PropsWithChildren, React.InputHTMLAttributes<HTMLInputElement> {
    label: string
    name: string
    inline?: boolean
    required?: boolean
    register?: UseFormRegister<FieldValues>
}

export default function FormGroup({ register, label, value, name, type, required, children, inline, ...props }: FormGroupProps) {
    const { t } = useTranslation()

    const isPassword = type === 'password'
    const [currentType, setCurrentType] = useState(type ?? 'text')
    const formRegister = register ? register(name, {
        required: required !== false ? `${t('O campo')} ${t(name.toLowerCase())} ${t('é obrigatório')}.` : false,
        value,
    }) : {}

    return (
        <div className={inline ? "form-group-full" : ''}>
            <label htmlFor={name.toLowerCase()} className="form-label">
                {t(label ?? name)}:
            </label>
            <div className='input-container'>
                <input
                    className="form-control"
                    type={currentType}
                    id={name.toLowerCase()}
                    required={required}
                    {...formRegister}
                    {...props}
                />
                {isPassword && (
                    <button 
                        className='show-password-btn' 
                        title="Ver senha" 
                        type='button' 
                        onClick={() => { 
                            setCurrentType(currentType === 'password' ? 'text' : 'password') 
                        }}
                    >
                        <FontAwesomeIcon icon={currentType === 'password' ? faEye : faEyeSlash} />
                    </button>
                )}
                {children}
            </div>
        </div>
    )
}
