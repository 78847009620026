import axios from 'axios'

const api = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: 1000 * 60 * 5,
})

api.interceptors.request.use(
    (config) => {
        if (!config.headers.Authorization) {
            config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('token') || ''
        }

        return config
    },
    (error) => Promise.reject(error)
)

export default api
