import { Card, CardHeader, CardTitle, CardToolbar } from '@/components/Card'
import ErrorHandler from '@/components/Error/ErrorHandler'
import DetalheFaturaModal from '@/components/Fatura/DetalheFaturaModal'
import GroupedFaturaHeader from '@/components/Fatura/GroupedFaturaHeader'
import PagamentoModal from '@/components/Fatura/PagamentoModal'
import PixModalContent from '@/components/Fatura/PixModalContent'
import FaturaCard from '@/components/FaturaCard'
import FormGroup from '@/components/FormGroup'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
import useAuth from '@/contexts/useAuth'
import useEmpresa from '@/contexts/useEmpresa'
import { getFaturas, groupByBoleto, groupByPix, isFaturaAberta, sortByExpiration, sortByPayment } from '@/services/fatura'
import { formatMoney } from '@/utils'
import React, { useEffect, useState } from 'react'
import { useDebounce } from "use-debounce"
import './Faturas.scss'

export default function Faturas() {
    const { user } = useAuth()
    const [faturaMes, setFaturaMes] = useState(new Date().getMonth() + 1)
    const [faturaAno, setFaturaAno] = useState(new Date().getFullYear())
    const [faturaPagaMesFilter] = useDebounce(faturaMes, 500);
    const [faturaPagaAnoFilter] = useDebounce(faturaAno, 500);
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [faturas, mutateFatura] = useState([])
    const { empresa } = useEmpresa()

    const [pixModal, setPixModal] = useState(null)
    const [detalhesFatura, setDetalheFatura] = useState(null)
    const [faturasSelecionadas, setFaturasSelecionadas] = useState([])
    const [pagamento, setPagamento] = useState(false)

    const updateFatura = async () => {
        if (faturaPagaMesFilter < 1 || faturaPagaMesFilter > 12 || faturaPagaAnoFilter < 2015 || faturaPagaAnoFilter > new Date().getFullYear() + 1) {
            return false
        }

        try {
            setIsLoading(true)
            const faturasRaw = await getFaturas(faturaPagaMesFilter, faturaPagaAnoFilter)
            mutateFatura(faturasRaw)
        } catch (error) {
            console.error(error)
            setError('Ocorreu um erro ao carregar as faturas')
        } finally {
            setIsLoading(false)
        }
    }    
    
    useEffect(() => {
        updateFatura()
    }, [faturaPagaMesFilter, faturaPagaAnoFilter])

    const selecionarFatura = (fatura) => {
        setFaturasSelecionadas([fatura, ...faturasSelecionadas])
    }

    const removerFatura = (cdFatura) => {
        setFaturasSelecionadas(faturasSelecionadas.filter((fatura) => fatura.CdFatura !== cdFatura))
    }

    useEffect(() => {
        if (!faturas || !user) return

        // Seleciona as faturas anteriores por padrão
        const diaFatura = !user.DiaFatura ? new Date().getDate() : user.DiaFatura

        const proximoMes = new Date()
        proximoMes.setDate(diaFatura)
        proximoMes.setMonth(proximoMes.getMonth() + 1)

        const dataFatura = new Date()
        dataFatura.setDate(diaFatura)

        const dataLimite = diaFatura < new Date().getDate() ? proximoMes : dataFatura

        const faturasAnteriores = faturas.filter(
            (fatura) =>
                isFaturaAberta(fatura) &&
                new Date(`${fatura.DataVencimento} `).getTime() <= dataLimite.getTime()
        )

        setFaturasSelecionadas(faturasAnteriores)
    }, [faturas, user])

    if (error) return <ErrorHandler error={error} />

    if (!faturas) {
        return <Loading type="primary" />
    }

    const groupedByBoleto = groupByBoleto(faturas)
    const groupedByPix = groupByPix(faturas)

    const faturasAbertas = faturas.filter(isFaturaAberta).sort(sortByExpiration)
    const faturasPagas = faturas.filter((fatura) => fatura.Pago).sort(sortByPayment)
    const enablePagamento = empresa.PermitePagamento && user.Automatico === 0 && (empresa.CdFormaBoleto > 0 || empresa.CdFormaCartao > 0 || empresa.CdFormaPix > 0)

    return (
        <>
            <DetalheFaturaModal detalhesFatura={detalhesFatura} onClose={() => setDetalheFatura(null)} />
            {pagamento && (
                <PagamentoModal
                    onPaymentComplete={async () => await updateFatura()}
                    faturas={faturasSelecionadas}
                    open={pagamento}
                    onClose={() => setPagamento(false)}
                />
            )}

            {pixModal &&
                <Modal open={true} className="pix-modal" >
                    <PixModalContent pixModal={{
                        qrcode: pixModal.qrcode,
                        emv: pixModal.Emv
                    }} onClose={() => setPixModal(null)} />
                </Modal>
            }

            {groupedByBoleto.length > 0 && (
                <>
                    <Card style={{ marginBottom: '25px' }}>
                        <CardHeader>
                            <CardTitle>Boletos a pagar</CardTitle>
                        </CardHeader>
                    </Card>
                    {groupedByBoleto.map((faturasBoletos) => (
                        <div key={faturasBoletos[0].CdBoleto} style={{ marginBottom: 10 }}>
                            <GroupedFaturaHeader onCancel={mutateFatura} fatura={faturasBoletos[0]} />

                            <div className="faturas">
                                {faturasBoletos.map((fatura) => (
                                    <FaturaCard
                                        fatura={fatura}
                                        onDetailClick={setDetalheFatura}
                                        key={fatura.CdFatura}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </>
            )}
            {groupedByPix.length > 0 && (
                <>
                    <Card style={{ marginBottom: '25px' }}>
                        <CardHeader>
                            <CardTitle>Pix a pagar</CardTitle>
                        </CardHeader>
                    </Card>
                    {groupedByPix.map((faturasPix, index) => (
                        <div key={index} style={{ marginBottom: 10 }}>
                            <GroupedFaturaHeader onCancel={mutateFatura} fatura={faturasPix[0]} openPixDetails={() => setPixModal(faturasPix[0])} />

                            <div className="faturas">
                                {faturasPix.map((fatura) => (
                                    <FaturaCard
                                        fatura={fatura}
                                        onDetailClick={setDetalheFatura}
                                        key={fatura.CdFatura}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </>
            )}

            {faturasAbertas.length > 0 && (
                <>
                    <Card className="fatura-card" style={{ marginBottom: '25px' }}>
                        <CardHeader>
                            <CardTitle>
                                Faturas em aberto
                                <p style={{ fontSize: 12, opacity: .6, marginBottom: 0, color: user.Automatico !== 0 ? '#0e830e' : '' }}>{user.Automatico !== 0 ? 'Pagamento automático' : 'Selecione as faturas que você deseja pagar'}</p>
                            </CardTitle>
                            <CardToolbar>
                                {faturasAbertas.length > 0 &&
                                    <span style={{ marginRight: '10px' }}>
                                        {formatMoney(
                                            (faturasSelecionadas.length > 0 ? faturasSelecionadas : faturasAbertas).reduce(
                                                (previous, current) => previous + parseFloat(current.Valor),
                                                0
                                            )
                                        )}
                                    </span>
                                }
                                {(enablePagamento && faturasSelecionadas.length > 0) &&
                                    <button className="btn btn-primary" onClick={() => setPagamento(true)}>
                                        Pagar
                                    </button>
                                }
                                {enablePagamento && <button
                                    className={`btn btn-light-${faturasSelecionadas.length === faturasAbertas.length ? 'danger' : 'primary'
                                        }`}
                                    style={{ marginLeft: 15 }}
                                    onClick={() =>
                                        faturasSelecionadas.length === faturasAbertas.length
                                            ? setFaturasSelecionadas([])
                                            : setFaturasSelecionadas(faturasAbertas)
                                    }
                                >
                                    {faturasSelecionadas.length === faturasAbertas.length
                                        ? 'Remover todas'
                                        : 'Selecionar todas'}
                                </button>}
                            </CardToolbar>
                        </CardHeader>
                    </Card>
                    {faturasAbertas.length > 0 && (
                        <div className="faturas">
                            {faturasAbertas.map((fatura) => (
                                <FaturaCard
                                    key={fatura.CdFatura}
                                    fatura={fatura}
                                    onDetailClick={setDetalheFatura}
                                    canSelect={enablePagamento}
                                    selected={faturasSelecionadas.find(
                                        (currentFatura) => currentFatura.CdFatura === fatura.CdFatura
                                    )}
                                    onSelect={selecionarFatura}
                                    onRemoveSelect={removerFatura}
                                />
                            ))}
                        </div>
                    )}
                </>
            )}

            <Card style={{ marginBottom: '25px' }} className="fatura-card">
                <CardHeader>
                    <CardTitle>Faturas pagas</CardTitle>
                    <CardToolbar style={{gap: 12}}>
                        <FormGroup label="Mês" name="fatura-mes" type="number" min="1" max="12" defaultValue={faturaMes} value={faturaMes} onChange={(e) => {
                            setFaturaMes(e.target.value)
                        }} />

                        <FormGroup label="Ano" name="fatura-ano" type="number" min="2015" max={new Date().getFullYear()} defaultValue={faturaAno} value={faturaAno} onChange={(e) => {
                            setFaturaAno(e.target.value)
                        }} />
                    </CardToolbar>
                </CardHeader>
            </Card>

            <div>
                {faturasPagas.length > 0 ? (
                    <>
                        {isLoading ? <Loading type="primary" /> : <div className="faturas">
                            {faturasPagas.map((fatura) => (
                                <FaturaCard key={fatura.CdFatura} fatura={fatura} onDetailClick={setDetalheFatura} />
                            ))}
                        </div>}
                    </>
                ) : (
                    <div>
                        <div style={{ margin: 0, paddingLeft: 12 }}>{isLoading ? <Loading type="primary" /> : 'Nenhuma fatura encontrada'}</div>
                    </div>
                )}
            </div>
        </>
    )
}
