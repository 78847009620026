import useAuth from '@/contexts/useAuth'
import { AxiosError } from 'axios'
import { useEffect } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { useSWRConfig } from 'swr'

interface ErrorHandlerProps {
    error?: AxiosError
}

export default function ErrorHandler({ error }: ErrorHandlerProps) {
    console.error(error)
    const location = useLocation()
    const { empresaURL } = useParams()
    const { setUser } = useAuth()
    const { cache } = useSWRConfig()

    useEffect(() => {
        if (error?.response?.status === 401) {
            localStorage.setItem('token', '')
            for (const key of cache.keys()) {
                cache.delete(key)
            }
            setUser(null)
        }
    }, [setUser, error?.response?.status, cache])

    if (error?.response?.status === 401) {
        return <Navigate to={`/${empresaURL}/login`} state={{ from: location }} replace />
    }

    return <div>Ocorreu um erro, tente novamente mais tarde.</div>
}
