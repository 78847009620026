import LogoLight from '@/assets/media/logo-light.png'
import useEmpresa from '@/contexts/useEmpresa'
import SmoothImage from './SmoothImage'

export default function Logo({ ...props }) {
    const { empresa } = useEmpresa()

    if (!empresa || !empresa.Logo) {
        return <SmoothImage {...props} src={LogoLight} alt="SistemaPET" />
    }

    return (
        <SmoothImage {...props} src={`${import.meta.env.VITE_LOGO_BASE_URL}/${empresa.Logo}`} alt={empresa.Fantasia} />
    )
}
