import { Card, CardBody, CardCollapseButton, CardFooter, CardHeader, CardTitle, CardToolbar } from '@/components/Card'
import Loading from '@/components/Loading'
import api from '@/services/api'
import React, { useReducer, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

function OrientacaoInput({ orientacao, registerOrientacoes }) {
    const { t } = useTranslation()
    return orientacao.Tipo === 1 ? (
        <div className="custom-radio">
            <label>
                <input
                    type="radio"
                    name={`opt${orientacao.CdCheck}`}
                    id={`opt${orientacao.CdCheck}`}
                    value="Sim"
                    {...registerOrientacoes(`opt${orientacao.CdCheck}`, {
                        value: orientacao.resposta?.Resposta ?? orientacao.Padrao,
                    })}
                />{' '}
                {t('Sim')}
            </label>
            <label>
                <input
                    type="radio"
                    name={`opt${orientacao.CdCheck}`}
                    id={`opt${orientacao.CdCheck}`}
                    value="Não"
                    {...registerOrientacoes(`opt${orientacao.CdCheck}`, {
                        value: orientacao.resposta?.Resposta,
                    })}
                />{' '}
                {t('Não')}
            </label>
        </div>
    ) : (
        <>
            <input
                type="text"
                className="form-control"
                id={`pergunta${orientacao.CdCheck}`}
                {...registerOrientacoes(`pergunta${orientacao.CdCheck}`, {
                    value: orientacao.resposta?.Resposta,
                })}
            />
        </>
    )
}

export default function OrientacoesTab({
    nav, cdAnimal
}) {
    const { handleSubmit: handleOrientacoesSubmit, register: registerOrientacoes } = useForm()

    const initialCollapsed = {
        banhoTosa: true,
        creche: true,
        hotel: true,
    }

    const [collapsed, toggleCollapsed] = useReducer((state, action) => {
        const newState = initialCollapsed

        newState[action] = !state[action]

        return newState
    }, initialCollapsed)
    
    const { t } = useTranslation()

    const [loadingOrientacoes, setLoadingOrientacoes] = useState(false)

    const { data: orientacoes, error: orientacoesError } = useSWR(`/empresa/orientacoes?animal=${cdAnimal}`, {
        revalidateOnFocus: false,
    })

    const onOrientacoesSubmit = async (formData) => {
        setLoadingOrientacoes(true)
        await api.post(`/empresa/orientacoes?animal=${cdAnimal}`, formData)
        setLoadingOrientacoes(false)
    }

    if (orientacoesError) return <ErrorHandler error={orientacoesError} />

    return (
        <CardBody nav={nav} name="orientacoes" className="orientacoes" style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
            {!orientacoes && <Loading type="primary"/>}
            {
                (orientacoes && orientacoes.gerais.length > 0) && <Card collapsable>
                    <CardHeader style={{ background: '#eef0f877' }}>
                        <CardTitle>{t('Orientações Gerais')}</CardTitle>
                    </CardHeader>
                    <CardBody
                        style={{ border: '3px solid #f7f8fc', borderTop: 'none' }}
                    >
                        <form className="form-orientacoes" onSubmit={handleOrientacoesSubmit(onOrientacoesSubmit)}>
                            {orientacoes.gerais.map((orientacao, index) => (
                                <div className="form-group" key={index}>
                                    <label className="form-label" htmlFor={`pergunta${orientacao.CdCheck}`}>
                                        {orientacao.Pergunta}
                                    </label>
                                    <OrientacaoInput registerOrientacoes={registerOrientacoes} orientacao={orientacao} />
                                </div>
                            ))}
                            <CardFooter className="justify-end">
                                <button className="btn btn-primary" type="submit" disabled={loadingOrientacoes}>
                                    {loadingOrientacoes && <Loading type="white" />}
                                    {t('Salvar')}
                                </button>
                            </CardFooter>
                        </form>
                    </CardBody>
                </Card>
            }
            {
                (orientacoes && orientacoes.banhoTosa.length > 0) && <Card collapsable>
                    <CardHeader style={{ background: '#eef0f877' }}>
                        <CardTitle>{t('Seu animal irá utilizar serviços de banho e tosa?')}</CardTitle>
                        <CardToolbar>
                            <CardCollapseButton
                                toggleCollapsed={() => toggleCollapsed('banhoTosa')}
                                collapsed={collapsed.banhoTosa}
                            >
                                <div style={{ padding: '10px 15px' }}>{t(collapsed.banhoTosa ? 'Sim' : 'Fechar')}</div>
                            </CardCollapseButton>
                        </CardToolbar>
                    </CardHeader>
                    <CardBody
                        collapsed={collapsed.banhoTosa}
                        style={collapsed.banhoTosa ? {} : { border: '3px solid #f7f8fc', borderTop: 'none' }}
                    >
                        <form className="form-orientacoes" onSubmit={handleOrientacoesSubmit(onOrientacoesSubmit)}>
                            {orientacoes.banhoTosa.map((orientacao, index) => (
                                <div className="form-group" key={index}>
                                    <label className="form-label" htmlFor={`pergunta${orientacao.CdCheck}`}>
                                        {orientacao.Pergunta}
                                    </label>
                                    <OrientacaoInput registerOrientacoes={registerOrientacoes} orientacao={orientacao} />
                                </div>
                            ))}
                            <CardFooter className="justify-end">
                                <button className="btn btn-primary" type="submit" disabled={loadingOrientacoes}>
                                    {loadingOrientacoes && <Loading type="white" />}
                                    {t('Salvar')}
                                </button>
                            </CardFooter>
                        </form>
                    </CardBody>
                </Card>
            }
            {
                (orientacoes && orientacoes.creche.length > 0) && <Card collapsable>
                    <CardHeader style={{ background: '#eef0f877' }}>
                        <CardTitle>{t('Seu animal irá utilizar serviços de creche?')}</CardTitle>
                        <CardToolbar>
                            <CardCollapseButton
                                toggleCollapsed={() => toggleCollapsed('creche')}
                                collapsed={collapsed.creche}
                            >
                                <div style={{ padding: '10px 15px' }}>{t(collapsed.creche ? 'Sim' : 'Fechar')}</div>
                            </CardCollapseButton>
                        </CardToolbar>
                    </CardHeader>
                    <CardBody
                        collapsed={collapsed.creche}
                        style={collapsed.creche ? {} : { border: '3px solid #f7f8fc', borderTop: 'none', }}
                    >
                        <form className="form-orientacoes" onSubmit={handleOrientacoesSubmit(onOrientacoesSubmit)}>
                            {orientacoes.creche.map((orientacao, index) => (
                                <div className="form-group" key={index}>
                                    <label className="form-label" htmlFor={`pergunta${orientacao.CdCheck}`}>
                                        {orientacao.Pergunta}
                                    </label>
                                    <OrientacaoInput registerOrientacoes={registerOrientacoes} orientacao={orientacao} />
                                </div>
                            ))}
                            <CardFooter className="justify-end">
                                <button className="btn btn-primary" type="submit" disabled={loadingOrientacoes}>
                                    {loadingOrientacoes && <Loading type="white" />}
                                    {t('Salvar')}
                                </button>
                            </CardFooter>
                        </form>
                    </CardBody>
                </Card>
            }
            {
                (orientacoes && orientacoes.hotel.length > 0) && <Card collapsable>
                    <CardHeader style={{ background: '#eef0f877' }}>
                        <CardTitle>{t('Seu animal irá utilizar serviços de hotel?')}</CardTitle>
                        <CardToolbar>
                            <CardCollapseButton
                                toggleCollapsed={() => toggleCollapsed('hotel')}
                                collapsed={collapsed.hotel}
                            >
                                <div style={{ padding: '10px 15px' }}>{t(collapsed.hotel ? 'Sim' : 'Fechar')}</div>
                            </CardCollapseButton>
                        </CardToolbar>
                    </CardHeader>
                    <CardBody
                        collapsed={collapsed.hotel}
                        style={collapsed.hotel ? {} : { border: '3px solid #f7f8fc', borderTop: 'none', }}
                    >
                        <form className="form-orientacoes" onSubmit={handleOrientacoesSubmit(onOrientacoesSubmit)}>
                            {orientacoes.hotel.map((orientacao, index) => (
                                <div className="form-group" key={index}>
                                    <label className="form-label" htmlFor={`pergunta${orientacao.CdCheck}`}>
                                        {orientacao.Pergunta}
                                    </label>
                                    <OrientacaoInput registerOrientacoes={registerOrientacoes} orientacao={orientacao} />
                                </div>
                            ))}
                            <CardFooter className="justify-end">
                                <button className="btn btn-primary" type="submit" disabled={loadingOrientacoes}>
                                    {loadingOrientacoes && <Loading type="white" />}
                                    {t('Salvar')}
                                </button>
                            </CardFooter>
                        </form>
                    </CardBody>
                </Card>
            }
        </CardBody>
    )
}
