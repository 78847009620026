import { ImgHTMLAttributes } from 'react'
import Logo from './Logo'
import SmoothImage from './SmoothImage'

export default function AnimalImage({ src, alt, className, ...props }: ImgHTMLAttributes<HTMLImageElement>) {
    return src ? (
        <SmoothImage
            className={'animal-image' + (className ? ` ${className}` : '')}
            {...props}
            src={import.meta.env.VITE_LOGO_BASE_URL + '/' + src}
            alt={alt}
        />
    ) : (
        <Logo className={'animal-image' + (className ? ` ${className}` : '')} {...props} />
    )
}
