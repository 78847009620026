import { type DetailedHTMLProps, type ImgHTMLAttributes, useState } from 'react'

interface SmoothImageProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {}

export default function SmoothImage({ alt, ...props }: SmoothImageProps) {
	const [loaded, setLoaded] = useState(false)
	return (
		<img
			{...props}
            alt={alt}
			style={{
				transition: 'transform 300ms',
				transform: `scale(${loaded ? 1 : 0})`,
			}}
			onLoad={() => setLoaded(true)}
		/>
	)
}
