import Modal from '../Modal'
import DetalheFaturaTable from './DetalheFaturaTable'
import { Fatura } from '@/types/fatura'

interface DetalheFaturaModalProps {
    onClose: () => void
    detalhesFatura: Fatura['Detalhes']
}

export default function DetalheFaturaModal({ detalhesFatura, onClose }: DetalheFaturaModalProps) {
    return (
        <Modal open={Boolean(detalhesFatura)}>
            <DetalheFaturaTable detalhesFatura={detalhesFatura} />
            <button className="btn btn-primary m-auto" style={{ marginTop: 25 }} onClick={onClose}>
                Fechar
            </button>
        </Modal>
    )
}
