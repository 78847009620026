export interface Empresa {
    CdEmpresa:         number;
    CNPJ:              string;
    Razao:             string;
    Fantasia:          string;
    Logo:              string;
    CdFormaCartao:     number;
    CdFormaBoleto:     number;
    CdFormaPix:        number;
    PermiteDeposito:   boolean;
    CdPais:            number;
    Telefone:          string;
    Logradouro:        string;
    Numero:            string;
    Cidade:            string;
    Estado:            string;
    URL:               string;
    PermitePagamento:  boolean;
    Modulos:           number[];
    ModuloMeuWhatsapp: boolean;
    FeatureFlags:      FeatureFlag[];
}

export interface FeatureFlag {
    CdFeatureFlag: FeatureFlagEnum;
    Nome:          string;
    Categoria:     string;
    Value:         boolean;
}

export enum FeatureFlagEnum {
    UploadAnimalImage = 1,
    AddHealthRecord = 2,
}
