import React, { DetailedHTMLProps, ReactElement } from 'react'

interface CardToolbarProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
{
    state?: [string, (newState: string) => void]
    children: ReactElement[]
}

export default function CardToolbar({ children, state, ...props }: CardToolbarProps) {
    return (
        <div className="card-toolbar" {...props}>
            {state
                ? children.map((child, index) => {
                      return React.cloneElement(child, {
                          key: index,
                          onClick: () => state[1](child.props.to),
                          className:
                              (child.props.className ? child.props.className : '') +
                              (state[0] === child.props.to ? 'active' : ''),
                      })
                  })
                : children}
        </div>
    )
}
