export function formatDatetime(date?: string) {
    if (!date || date === '0000-00-00' || date === '0000-00-00 00:00:00') return ''

    return (new Date(date.replace(/-/g, '/'))).toLocaleDateString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
    })
}

export function formatDate(date?: string) {
    if (!date || date === '0000-00-00') return ''

    return (new Date((date + ' ').replace(/-/g, '/'))).toLocaleDateString('pt-BR')
}

export function formatMoney(money: string|number) {
    return parseFloat(money.toString()).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    })
}

export function htmlDecode(input: string) {
    const doc = new DOMParser().parseFromString(input, 'text/html')
    return doc.documentElement.textContent
}

export function logger(message: string, type: 'log' | 'info' | 'warn' | 'error' = 'log') {
    localStorage.getItem('debug') && console[type](message)
}

export function getFileBase64(file?: File) {
    if (!file) return Promise.resolve(undefined)

    const reader = new FileReader()
    reader.readAsDataURL(file)

    return new Promise<string | undefined>((resolve, reject) => {
        reader.onload = () => resolve(reader.result?.toString())
        reader.onerror = reject
    })
}

export function validateEmail(email: string) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

export function validatePhone(phone: string) {
    return phone.replace(/\D/g, '').length >= 10
}

export function getTipoPix(tipoPixId?: number) {
    switch (tipoPixId) {
        case 1: return 'CPF/CNPJ'
        case 2: return 'Email'
        case 3: return 'Telefone'
        case 4: return 'Aleatória'
        default: return ''
    }
}
